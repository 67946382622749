import type { AdService } from './ad-service';

export abstract class AuctionService {
	adService: AdService;

	constructor(adService: AdService) {
		this.adService = adService;
	}

	/**
	 * Async function to run an auction for the given slotIds.
	 */
	abstract runAuction(slotIds: string[]): Promise<unknown>;
}
