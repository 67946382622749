import { PrebidBidder } from './base';

/**
 * TripleLift (triplelift Prebid vendor)
 * Bidder documentation: https://docs.prebid.org/dev-docs/bidders/triplelift.html
 * @extends PrebidBidder
 */
export class TripleLift extends PrebidBidder {
	static get bidder() {
		return 'triplelift';
	}

	bidParams(bidConfig: BidRequestConfiguration) {
		if (!bidConfig.inventoryCode) {
			return null;
		}
		return super.bidParams(bidConfig);
	}
}
