import { getCookie } from '@/utils/cookies';
import { isProduction } from '@/utils/is-production';
import type { SourcepointContext } from '@/types/sourcepoint';
import type { VendorConsents, ArenaCookieContext } from '@/types/gdpr';
import { VENDOR_IDS, TCF2_PURPOSE_IDS } from '@/components/GDPR/constants';
import { ComplianceType, SP_PURPOSE_IDS } from '@/utils/consent/constants';

export const disableGdpr = () =>
	window.location.hash.includes('disableGdpr') && !isProduction();

export const assumeGdprApplicable = () =>
	window.location.hash.includes('assumeGdprApplicable') && !isProduction();

/**
 * The function `getCountryCode` returns the country code from the provided `ArenaCookieContext`
 * object, or an empty string if the country code is not available.
 * @param {ArenaCookieContext} context - The `context` parameter in the `getCountryCode` function is of
 * type `ArenaCookieContext`. It is used to access the country code information stored in the context
 * object. If the `countryCode` property is not present in the context or is empty, the function will
 * return an empty string
 * @returns The function `getCountryCode` returns the `countryCode` property from the `context` object
 * in the `ArenaCookieContext`. If `context.countryCode` is falsy, it returns an empty string.
 */
export const getCountryCode = (context: ArenaCookieContext) => {
	if (!context.countryCode) {
		return '';
	}

	return context.countryCode;
};

/**
 * This TypeScript function returns the demonetized reason from a ArenaCookieContext object, or 'Unknown' if
 * no reason is provided.
 * @param {ArenaCookieContext} context - The `context` parameter in the `getDemonetizedReason` function is of
 * type `ArenaCookieContext`. It is used to access information related to the demonetization reason for a
 * specific context.
 * @returns The function `getDemonetizedReason` will return the demonetized reason from the
 * `ArenaCookieContext` object. If the demonetized reason is not available in the context, it will return
 * 'Unknown'.
 */
export const getDemonetizedReason = (context: ArenaCookieContext | null) => {
	if (!context?.demonetizedReason) {
		return 'Unknown';
	}

	return context.demonetizedReason;
};

/**
 * The function `getRegionCode` returns the region code from the provided `ArenaCookieContext` object,
 * or an empty string if the region code is not available.
 * @param {ArenaCookieContext} context - The `context` parameter in the `getRegionCode` function is of
 * type `ArenaCookieContext`. It is used to access the region code property to retrieve the region code
 * value. If the `regionCode` property is not present or is falsy, the function will return an empty
 * string.
 * @returns The function `getRegionCode` will return the `regionCode` from the `context` object if it
 * exists, otherwise it will return an empty string.
 */
export const getRegionCode = (context: ArenaCookieContext) => {
	if (!context.regionCode) {
		return '';
	}

	return context.regionCode;
};

/**
 * The function `getIsDemonetized` returns a boolean value indicating whether the `isDemonetized` exists in the context.
 * @param {ArenaCookieContext} context - ArenaCookieContext
 */
export const getIsDemonetized = (context: ArenaCookieContext | null) =>
	context?.isDemonetized ?? false;

/**
 * The function `getIsApplicable` returns a boolean value indicating whether the context is in the
 * European Economic Area (EEA).
 * @param {ArenaCookieContext} context - ArenaCookieContext
 */
export const getIsApplicable = (context: ArenaCookieContext | null) =>
	context?.inEEA ?? false;

/**
 * The function `getArenaUid` returns the UID from the provided `ArenaCookieContext` if it exists,
 * otherwise it returns null.
 * @param {ArenaCookieContext} context - The `context` parameter in the `getArenaUid` function is of
 * type `ArenaCookieContext`.
 * @returns The function `getArenaUid` returns the `uid` property from the `context` object in the
 * `ArenaCookieContext`. If `context.uid` is falsy, it returns `null`.
 */
export const getArenaUid = (context: ArenaCookieContext) => {
	if (!context.uid) {
		return null;
	}

	return context.uid;
};

export const getPurposeConsentValues = (consents: VendorConsents) =>
	consents.purposeConsents || {};

export const getVendorConsentValues = (consents: VendorConsents) =>
	consents.vendorConsents || {};

export const sourcepointToIabMap = (purposeGrants: Record<string, boolean>) => {
	const purposes: Record<string, boolean> = {};

	Object.keys(purposeGrants).forEach((key: string) => {
		purposes[SP_PURPOSE_IDS[key] || key] = purposeGrants[key];
	});

	return purposes;
};

// This function ensures that all purposes for the given vendor are granted
const everyConsentForPurposes = (
	purposes: number[] | string[],
	purposeConsents: Record<number, boolean>,
): boolean => {
	return purposes.every((purpose: number | string) => {
		let innerPurpose: number | number[] | string = purpose;

		// The first time innerPurpose is a string, we convert it to a array of numbers
		// The second time innerPurpose is a number
		if (typeof innerPurpose === 'string') {
			innerPurpose = TCF2_PURPOSE_IDS[innerPurpose];
		}

		// If innerPurpose is an array, we recursively call everyConsentForPurposes
		if (Array.isArray(innerPurpose)) {
			return everyConsentForPurposes(innerPurpose, purposeConsents);
		}

		return purposeConsents[innerPurpose];
	});
};

export const consentGivenForPurposes = (
	purposes: string[],
	consents: null | VendorConsents,
	vendorMapConsents?: Record<string, boolean>,
) => {
	// To hide the content if the user has not given consent
	if (!consents) {
		return true;
	}

	const purposeCosent = vendorMapConsents || consents.purposeConsents;

	return (
		consents &&
		purposeCosent &&
		everyConsentForPurposes(purposes, purposeCosent)
	);
};

export const consentGivenForVendorPurposes = (
	vendorId: string,
	purposes: string[],
	consents: null | VendorConsents,
) => {
	const resolvedVendor = VENDOR_IDS[vendorId] || vendorId;

	// To hide the content if the user has not given consent
	if (
		!consents ||
		!consents.vendorConsents ||
		!consents.vendorConsents[resolvedVendor]
	) {
		return false;
	}

	// if no purpose argument is given, then assert consent
	// is given based on vendor alone.
	if (!purposes || !purposes.length) {
		return true;
	}

	const vendorPurposeConsents =
		consents.vendorPurposeConsents?.[resolvedVendor];

	return consentGivenForPurposes(purposes, consents, vendorPurposeConsents);
};

/**
 * The function `gppAccepted` checks if the GPP consent is accepted based on the Sourcepoint context
 * and cookie information.
 * @param {null | SourcepointContext} [sourcepointContext] - The `sourcepointContext` parameter is an
 * optional object that contains information related to consent management. It can have properties such
 * as `consentType` which indicates the type of consent (e.g., GPP) and can be used to determine if the
 * GPP (Google Publisher Policies) is accepted
 * @returns The function `gppAccepted` will return `true` if the `sourcepointContext` is not null and
 * the `consentType` is GPP, and the cookie 'gppReject' is not set to 'true'. Otherwise, it will return
 * `false`.
 */
export const gppAccepted = (sourcepointContext?: null | SourcepointContext) => {
	if (!sourcepointContext) {
		return false;
	}

	if (sourcepointContext.consentType === ComplianceType.GPP) {
		const gppReject = getCookie('gppReject');

		return gppReject === 'true';
	}

	return true;
};
