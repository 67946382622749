import { Zones } from '@/types/ads';

import { sizesSupportedForBreakpoint } from '../zones';

const FLOOR_DEFAULT = 0.1;
const floorOffsetsByMediaType = {
	'*': 0,
	banner: 0,
	native: 0,
	video: 0.15,
};

const PRICING_BREAKPOINT = 20;
export const PRICING_MAX_BUCKET = 50;

// Prebid auction timeout for display
export const AUCTION_DISPLAY_TIMEOUT = 1500;

// Prebid auction timeout for video
export const AUCTION_VIDEO_TIMEOUT = 2100;

export const BasePrebidConfig: PrebidConfig = {
	adpod: {
		brandCategoryExclusion: true,
	},
	bidderSequence: 'fixed',
	cache: {
		url: 'https://prebid.adnxs.com/pbc/v1/cache',
	},
	deviceAccess: true,
	enableSendAllBids: false,
	enableTIDs: true,
	floors: {
		data: {
			currency: 'USD',
			modelVersion: 'Default Ad Unit Floors',
			schema: {
				fields: ['mediaType'],
			},
			skipRate: 0,
			values: {
				'*': FLOOR_DEFAULT + floorOffsetsByMediaType['*'],
				banner: FLOOR_DEFAULT + floorOffsetsByMediaType.banner,
				native: FLOOR_DEFAULT + floorOffsetsByMediaType.native,
				video: FLOOR_DEFAULT + floorOffsetsByMediaType.video,
			},
		},
	},
	ix: {
		firstPartyData: {},
	},
	maxRequestsPerOrigin: 100,
	paapi: {
		defaultForSlots: 1,
		enabled: true,
		gpt: {
			configWithTargeting: false,
		},
	},
	priceGranularity: {
		buckets: [
			{
				increment: 0.05,
				max: PRICING_BREAKPOINT,
				precision: 2,
			},
			{
				increment: 1.0,
				max: PRICING_MAX_BUCKET,
				precision: 2,
			},
		],
	},
	realTimeData: {
		auctionDelay: 50,
		dataProviders: [],
	},
	rubicon: {
		singleRequest: true,
	},
	sizeConfig: [
		{
			labels: ['mobile', 'A'],
			mediaQuery: '(max-width: 29.99em)',
			// These are all the sizes that are appropriate to size "A" (mobile, aka phone)
			sizesSupported: sizesSupportedForBreakpoint('A'),
		},
		{
			labels: ['desktop', 'B'],
			mediaQuery: '(min-width: 30em) and (max-width: 63.99em)',
			// These are all the sizes that are appropriate to size "B" (desktop, aka tablet)
			sizesSupported: sizesSupportedForBreakpoint('B'),
		},
		{
			labels: ['desktop', 'C'],
			mediaQuery: '(min-width: 64em) and (max-width: 89.99em)',
			// These are all the sizes that are appropriate to size "C" (desktop, aka laptop)
			sizesSupported: sizesSupportedForBreakpoint('C'),
		},
		{
			labels: ['desktop', 'D'],
			mediaQuery: '(min-width: 90em)',
			// These are all the sizes that are appropriate to size "D" (desktop)
			sizesSupported: sizesSupportedForBreakpoint('D'),
		},
	],
	targetingControls: {
		alwaysIncludeDeals: true,
	},
	useBidCache: true,
	userSync: {
		auctionDelay: 300,
		filterSettings: {
			iframe: {
				bidders: '*',
				filter: 'include',
			},
		},
		syncDelay: 5000,
		topics: {
			bidders: [
				{
					bidder: 'pubmatic',
					expiry: 7, // Cache topics data for 7 days. Default is 21.
					iframeURL:
						'https://ads.pubmatic.com/AdServer/js/topics/topics_frame.html',
				},
			],
			maxTopicCaller: 1,
		},
		userIds: [
			{
				name: 'pairId',
				params: {
					liveramp: {
						storageKey: '_lr_pairId',
					},
				},
			},
		],
	},
	yahoossp: {
		mode: 'all',
	},
};

export const BannerMediaTypes = {
	[Zones.adhesion]: {
		pos: 7,
	},
	[Zones.below_content]: {
		pos: 3,
	},
	[Zones.below_content_card]: {
		pos: 3,
	},
	[Zones.fixed_bottom]: {
		pos: 5,
	},
	[Zones.header]: {
		pos: 4,
	},
	[Zones.in_card]: {
		pos: 3,
	},
	[Zones.in_content]: {
		pos: 3,
	},
	[Zones.interstitial]: {
		pos: 7,
	},
	[Zones.sidebar]: {
		pos: 6,
	},
};

export const InstreamMediaType = {
	api: [2],
	context: 'instream',
	delivery: [2],
	h: 529,
	linearity: 1,
	maxbitrate: 30000,
	maxduration: 30,
	mimes: ['video/mp4', 'video/webm', 'application/javascript'],
	minbitrate: 300,
	minduration: 5,
	placement: 1,
	// playbackmethod will be a single integer instead of an array soon.
	playbackmethod: [6], // 6: Initiates on entering viewport with sound off by default.
	playerSize: [640, 480],
	plcmt: 2,
	pos: 1,
	protocols: [2, 3, 5, 6],
	skip: 1,
	skipafter: 6,
	startdelay: 0,
	w: 940,
};

export const NativeMediaType = {
	body: {
		required: false,
		sendId: true,
	},
	clickUrl: {
		required: true,
		sendId: true,
	},
	image: {
		// An array of { min_height, min_width, ratio_height, ratio_width } objects
		// TBD: verify these are suitable for Surfer's design
		aspect_ratios: [
			{
				min_height: 400,
				min_width: 600,
				ratio_height: 2,
				ratio_width: 3,
			},
		],
		required: true,
		sendId: true,
		sizes: [600, 400],
	},
	sponsoredBy: {
		len: 80,
		required: true,
		sendId: true,
	},
	title: {
		len: 80,
		required: true,
		sendId: true,
	},
};

export const OutstreamMediaType = {
	api: [2],
	context: 'outstream',
	delivery: [2],
	linearity: 1,
	maxbitrate: 30000,
	maxduration: 60,
	mimes: ['video/mp4', 'video/webm', 'application/javascript'],
	minbitrate: 300,
	minduration: 5,
	placement: 3, // in article
	playbackmethod: [6], // 6: Initiates on entering viewport with sound off by default.
	playerSize: [640, 480],
	// Reference: https://github.com/InteractiveAdvertisingBureau/AdCOM/blob/develop/AdCOM%20v1.0%20FINAL.md#list--placement-subtypes---video-
	plcmt: 4, // Standalone
	pos: 2,
	protocols: [2, 3, 5, 6],
	renderer: {
		backupOnly: true,
		render: (bid: ANOutstreamBid) => {
			window.ANOutstreamVideo.renderAd({
				adResponse: bid.adResponse,
				targetId: bid.adUnitCode,
			});
		},
		url: 'https://acdn.adnxs.com/video/outstream/ANOutstreamVideo.js',
	},
	skip: 0,
};
