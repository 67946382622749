import { PrebidBidder } from './base';

/**
 * Criteo (criteo Prebid vendor)
 * Bidder documentation: https://docs.prebid.org/dev-docs/bidders/criteo.html
 * @extends PrebidBidder
 */
export class Criteo extends PrebidBidder {
	static get bidder() {
		return 'criteo';
	}

	bidParams(bidRequestConfiguration: BidRequestConfiguration) {
		// Exclude groupId from bid parameters; it's present in `bidRequestConfiguration`,
		// but is a legacy property that we aren't passing to PrebidJS.
		const { groupId, ...p } = super.bidParams(bidRequestConfiguration) ?? {};
		if (!p.networkId || !p.pubid) {
			return null;
		}
		return p;
	}
}
