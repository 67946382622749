import type { FC } from 'react';
import type { ClientSiteConfig } from '@/temporary-site-config';

import type { AdService } from './ad-service';
import { PrebidJS, PrebidBootstrap } from './prebid';
import type { AuctionService } from './auction-service';
import { AmazonUAM, AmazonUAMBootstrap } from './amazonuam';

// FIXME: should be configurable per site
const prebidEnabled = true;
const amazonUAMEnabled = true;

interface AuctionBootstrapProps {
	config: ClientSiteConfig;
}

export const AuctionBootstrap: FC<AuctionBootstrapProps> = ({ config }) => (
	<>
		{prebidEnabled && <PrebidBootstrap />}
		{amazonUAMEnabled && <AmazonUAMBootstrap config={config} />}
	</>
);

export class AuctionManager {
	services: AuctionService[] = [];

	constructor(adService: AdService) {
		// If one of the (non-Amazon UAM) vendors in the object have an 'enabled' property, enable PrebidJS
		if (prebidEnabled) {
			this.services.push(new PrebidJS(adService));
		}

		// If the Amazon UAM vendor is enabled, enable Amazon UAM
		if (amazonUAMEnabled) {
			this.services.push(new AmazonUAM(adService));
		}
	}

	runAuction(slotIds: string[]) {
		return Promise.all(
			this.services.map((service) => service.runAuction(slotIds)),
		);
	}
}
