// Custom vendor IDs (Sourcepoint-specific)
const CUSTOM_VENDOR_IDS = {
	admiral: '64403e628af59f049d3cab37', // analytics vendor; see server/web/template-modules/thirdParty/partials/admiral.pug
	arcspan: '6425d7744f9aee6206619515',
	bandcamp: '5f102879d9efe96bbebaf30b', // embed vendor; using vendor id for emdbedly
	blueconic: '5eb32dea09c3f947e75e6f02', // see server/web/template-modules/thirdParty/partials/blueConic.pug
	bombora: '5e37fc3e56a5e6615457a529', // analytics vendor; see server/web/template-modules/thirdParty/partials/bombora.pug
	brightcove: '5ec796b4320b5a4efd764e0f', // embed vendor
	brightedge: '5f3bfd07a4724a6aaf5d7684',
	chartbeat: '5ea172e36ede87504f7b4590', // analytics vendor; see server/web/template-modules/thirdParty/partials/chartbeat.pug
	chicory: '62d6e92851a820068650d77f', // embed ventr; see server/web/template-modules/thirdParty/partials/chicory.pug
	civicscience: '6373b1e7b35e4204c8062651', // see server/web/template-modules/thirdParty/partials/civicscience.pug
	clicksco: '5f3bfd07a4724a6aaf5d7685', // content recommendation
	colossusssp: '5e839a38b8e05c2bbe33f2fc', // prebid vendor
	embedly: '5f102879d9efe96bbebaf30b', // embed vendor; see htdocs/bootscripts/webcomponents/embedlyEmbed/main.js
	excoplayer: '62d137270550eb06a62f5855', // embed vendor; see htdocs/bootscripts/webcomponents/exCoPlayer/main.js
	facebook: '5e716fc09a0b5040d575080f', // embed, analytics vendor
	fubotv: '60cb5ddd1bfbb16ffb1ed67d', // embed vendor
	hotjar: '5ee7add94c24944fdb5c5ac6', // analytics vendor
	ihsmarkit: '5f45507146d0607f71d2d2b6', // analytics vendor
	instagram: '5e717c8e69966540e4554f05', // embed vendor
	jeeng: '6104438de751ef061d94b7d5', // see server/web/template-modules/thirdParty/partials/jeeng.pug
	jwplayer: '5f0f1187b8e05c109c2b8464', // embed vendor
	kickstarter: '5f3ee3dc8957bf8047ac94cc', // embed vendor
	krux: '5e542b3a4cd8884eb41b5a76', // prebid vendor
	linkedin: '5e777e669cb08971eb078d71', // see server/web/template-modules/thirdParty/partials/linkedInInsight.pug
	lockr: '66bcddac14f45d053608b6bf', // identity, consent, data provider
	lytics: '5f3bfd07a4724a6aaf5d7687', // analytics vendor
	mantis: '5f3bfd07a4724a6aaf5d7688', // prebid vendor
	maven: '5f4fdc68ccd23307f4a3fe5f',
	metabet: '61115122a5573307dc2c043c', // embed vendor
	narrativ: '5f3bfd07a4724a6aaf5d768d', // see server/web/template-modules/thirdParty/partials/narrativ.pug
	nbcsports: '5f45507146d0607f71d2d2b6', // embed vendor; see tmlExternalNbcSports.pug
	parsely: '5efb47c3a86ed40d775a6693', // see server/web/template-modules/thirdParty/partials/parsely.pug
	permutive: '5eff0d77969bfa03746427eb',
	pinterest: '5e839a38b8e05c4e491e738e', // embed vendor
	polldaddy: '5f1b2fbeb8e05c3057240f6f', // embed vendor
	pubexchange: '5f3bfd07a4724a6aaf5d768a', // see htdocs/bootscripts/webcomponents/pubExchange/main.js
	pushly: '61043ad6ba4a720564a7cf25',
	qualtrics: '5f1b2fbeb8e05c3057240fa0', // see server/web/template-modules/thirdParty/partials/qualtrics.pug
	sailthru: '5f3bfd07a4724a6aaf5d768b',
	sendtonews: '5f4975d6c34a678c4f80dd99', // video vendor
	skimlinks: '5efb47d829507407f91c4d4e', // server/web/template-modules/thirdParty/partials/skimlinks.pug
	sortable: '5f2d22a6b8e05c02ba124dc2', // prebid vendor
	soundcloud: '5e7179e49a0b5040d5750812', // embed vendor
	spinyai: '65b42656f32e76067bde6346', // see server/web/template-modules/thirdParty/partials/spinyai.pug
	sportradar: '5f1b2fbeb8e05c306e139f88', // embed vendor
	spotify: '5e7f6927b8e05c111d01b40f', // embed vendor
	stellar: '668312982bf7b00772c2b99f',
	tealium: '5f1b2fbeb8e05c30686fd72a', // server/web/template-modules/thirdParty/partials/tealium.pug
	ted: '5f3ee41ef16f426d9f757c24', // embed vendor
	theplatform: '5f3ee4d4ed1f54674a088416', // embed vendor
	threatmetrix: '636e6f897bd70406aa851625', // server/web/template-modules/thirdParty/partials/threatMetrix.pug
	tiktok: '5e7f6927b8e05c4e491e7380', // embed vendor
	trackonomics: '63ee3f95b6a2e004d7667709',
	twitter: '5e71760b69966540e4554f01', // embed vendor
	undertone: '5f1b2fbeb8e05c306e139f31', // prebid vendor
	vidazoo: '5f1b2fbeb8e05c306c059802',
	viglink: '5f05be4cb8e05c109c2b83d2', // server/web/template-modules/thirdParty/partials/viglink.pug
	vimeo: '5f1b2fbeb8e05c30686fd74c', // embed vendor
	vox: '60943f2c5a38c71211313c38', // comment
	webflix: '66831244fedf14074adfa3d6', // Web Flix
	youtube: '5e7ac3fae30e7d1bc1ebf5e8', // embed vendor
};

export const VENDOR_IDS: Record<string, number | string> = {
	'33acrosshi': 58, // prebid vendor
	adobe: 264, // analytics vendor
	amazonuam: 68, // header bidding vendor (aka sizmek)
	appnexus: 32, // prebid vendor (aka xandr)
	brandmetrics: 422, // Brand Metrics Sweden AB; analytics vendor
	cedato: 672, // prebid vendor
	comscore: 77, // analytics vendor
	criteo: 91,
	dailymotion: 573, // video third-party; embed service
	'freewheel-ssp': 285, // prebid vendor
	google: 755, // analytics vendor
	gumgum: 61, // prebid vendor
	ix: 10, // prebid vendor
	kargo: 972, // prebid vendor
	liveintent: 148, // prebid vendor
	nielsen: 373, // analytics vendor
	openweb: 280, // comments/ads vendor; // see server/web/template-modules/tmlExternalOpenWebLiveBlog/tmlExternalOpenWebLiveBlog.pug
	openx: 69, // prebid vendor
	outbrain: 164, // content recommendation
	pubmatic: 76, // prebid vendor
	pulsepoint: 81, // prebid vendor
	quantcast: 11, // analytics vendor
	rhythmone: 36, // prebid vendor
	rise: 1043,
	rubicon: 52, // prebid vendor
	sovrn: 13, // prebid vendor
	taboola: 42, // content recommendation; see htdocs/bootscripts/webcomponents/taboola/main.js
	teads: 132, // prebid vendor
	triplelift: 28, // prebid vendor
	ttd: 21, // prebid vendor
	yahoossp: 25, // prebid vendor
	...CUSTOM_VENDOR_IDS,
};

export const TCF2_PURPOSE_IDS: Record<string, number[]> = {
	// TCF 1 purpose aliases for TCF 2 purpose codes; these allow general purpose declarations
	// to be used in web components, but require one or many specific purposes to be consented.
	advertising: [2, 3, 4, 7],

	// Select basic ads
	// Ads can be shown to you based on the content you’re viewing, the app you’re using, your
	// approximate location, or your device type.
	'advertising/basic': [2],

	// Select personalised ads
	// Personalised ads can be shown to you based on a profile about you.
	'advertising/personalized': [4],

	// Create a personalised ads profile
	// A profile can be built about you and your interests to show you personalised ads that are
	// relevant to you.
	'advertising/profile': [3],

	// Require "All" purpose consents
	all: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],

	// TCF 1 purpose aliases for TCF 2 purpose codes; these allow general purpose declarations
	// to be used in web components, but require one or many specific purposes to be consented.
	content: [5, 6, 8],

	// Use limited data to select content
	'content/basic': [11],

	// Select personalised content
	// Personalised content can be shown to you based on a profile about you.
	'content/personalized': [6],

	// Create a personalised content profile
	// A profile can be built about you and your interests to show you personalised content that is
	// relevant to you.
	'content/profile': [5],

	// TCF 1 purpose aliases for TCF 2 purpose codes; these allow general purpose declarations
	// to be used in web components, but require one or many specific purposes to be consented.
	measurement: [7, 8, 9, 10],

	// Measure ad performance
	// The performance and effectiveness of ads that you see or interact with can be measured.
	'measurement/advertising': [7],

	// Measure content performance
	// The performance and effectiveness of content that you see or interact with can be measured.
	'measurement/content': [8],

	// Develop and improve products
	// Your data can be used to improve existing systems and software, and to develop new products.
	'measurement/development': [10],

	// Apply market research to generate audience insights
	// Market research can be used to learn more about the audiences who visit sites/apps and
	// view ads.
	'measurement/research': [9],

	// TCF 1 purpose aliases for TCF 2 purpose codes; these allow general purpose declarations
	// to be used in web components, but require one or many specific purposes to be consented.
	personalization: [3, 4, 5, 6],

	// Store and/or access information on a device
	// Cookies, device identifiers, or other information can be stored or accessed on your device for
	// the purposes presented to you.
	storage: [1],
};

export const TCF2_PURPOSE_KEYS = [
	'advertising',
	'advertising/basic',
	'advertising/personalized',
	'advertising/profile',
	'all',
	'content',
	'content/basic',
	'content/personalized',
	'content/profile',
	'measurement',
	'measurement/advertising',
	'measurement/content',
	'measurement/development',
	'measurement/research',
	'personalization',
	'storage',
] as const;

// The Arena UID endpoint is expected to respond in this amount of milliseconds. The long timeout
// is an experiment controlled by feature flag and may be removed when the experiment concludes;
// see ADS-3966 for details. The BaseGdprStatus class supplies a arenaUidTimeout method that
// determines which timeout to use. arenaUidTarget is a contextually available variable, coming
// from gdpr.pug and its inline inclusion within page.pug.
export const UID_TIMEOUT = 5000;
export const UID_LONG_TIMEOUT = 10 * 1000;

export const GEO_COOKIE = 'ArenaGeo';
export const ID_COOKIE = 'ArenaID';

export const ARENA_ENDPOINT_V3 = 'https://uid.thearenagroup.net/v3';

export const US_DEBUG_CONTEXT = {
	build: '59',
	countryCode: 'US',
	inEEA: false,
	isDemonetized: false,
	regionCode: 'PA',
	uid: 'WzmLYjUt7kNuYfzvKqsRmw',
	version: 3,
};

export const EAA_DEBUG_CONTEXT = {
	build: '59',
	countryCode: 'DE',
	inEEA: true,
	isDemonetized: false,
	uid: 'WzmLYjUt7kNuYfzvKqsRmw',
	version: 3,
};
