import { PrebidBidder } from './base';

/**
 * YahooAds (yahoossp Prebid vendor, aka yahooAds)
 * Bidder documentation: https://docs.prebid.org/dev-docs/bidders/yahooAdvertising.html
 * @extends PrebidBidder
 */
export class YahooAds extends PrebidBidder {
	static get bidder() {
		return 'yahoossp';
	}

	bidParams(bidRequestConfiguration: BidRequestConfiguration) {
		// We expose YahooAds's DCN parameter (as required per the
		// Prebid.js docs) as "siteId", a more recognizable term
		// that is also used in their documentations.
		const siteIds = this.config.siteIds as SiteIdConstraint[];
		if (!siteIds || siteIds.length === 0) {
			return null;
		}
		const { constraints, pos } = bidRequestConfiguration;
		const yahooDCNs = siteIds.filter(
			(idObj) => idObj.constraints === constraints,
		);
		if (yahooDCNs.length === 0) {
			return null;
		}
		return {
			dcn: yahooDCNs[0].siteId,
			pos,
		};
	}
}
