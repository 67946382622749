import type { TemporarySiteConfig } from '@/temporary-site-config';

// TODO: above implementation will be revisited when
// we have a single codebase for rendering sites

export const getAuthConfig = (siteConfig: TemporarySiteConfig) => {
	const { logo, siteKeyword } = siteConfig;
	return {
		authTheme: {
			logo: logo?.default.imageUrl || '',
		},
		getC3UserInfo: false,
		siteKeyword,
	};
};

export const isValidUrl = (url: null | string | undefined): boolean => {
	if (!url) {
		return false;
	}
	return /^(http|https)?:\/\//i.test(url);
};

/** Target URL precedence: query parameter, then prop, then current domain */
export const getTargetUrl = (targetUrlProp: string) => {
	// defaults origin and queryTargetUrl to empty string to give homepage as their default value
	let origin = '';
	let queryTargetUrl = '';
	if (typeof window !== 'undefined') {
		const urlParams = new URLSearchParams(window.location.search);
		// returns empty string if no targetUrl is found
		queryTargetUrl = urlParams.get('targetUrl') || '';
		origin = window.location.origin;
	}
	let target = '';
	if (isValidUrl(queryTargetUrl)) {
		target = queryTargetUrl;
	} else if (isValidUrl(targetUrlProp)) {
		target = targetUrlProp;
	} else if (isValidUrl(origin)) {
		target = origin;
	}
	return target;
};

// Function uses siteConfig to return user cookie configuration
export const getUserCookieConfig = (siteConfig: TemporarySiteConfig) => ({
	domain: siteConfig.siteProductionDomain.split('.').slice(-2).join('.'),
	name: 'user',
	rgisName: 'RGIS',
	secure: process.env.NODE_ENV === 'production',
});

export const UNAUTHORIZED = 'User not authorized';

const HTTP_UNAUTHORIZED = 401;

export const rgisOrEmailJwtFetch = (
	emailJwt: string | undefined,
	rgisCookie: string | undefined,
	resource: RequestInfo,
	reqData?: RequestInit,
): Promise<Response> => {
	return new Promise((resolve, reject) => {
		let rgis = rgisCookie;

		const headers = new Headers(reqData?.headers);
		if (emailJwt) {
			headers.set('x-email-jwt', emailJwt);
		} else if (rgis) {
			if (rgis.startsWith('"') && rgis.endsWith('"')) {
				rgis = rgis.slice(1, -1);
			}
			headers.set('x-rgis', rgis);
		} else {
			reject(new Error(UNAUTHORIZED));
		}

		const requestData = {
			...(reqData || {}),
			headers,
		};

		fetch(resource, requestData).then((resp) => {
			if (resp.status === HTTP_UNAUTHORIZED) {
				reject(new Error(UNAUTHORIZED));
			}
			resolve(resp);
		}, reject);
	});
};

/**
 * Clean RGIS cookie if there are leading and trailing quotes
 * @param rgis
 * @returns
 */
export const getCleanRGIS = (rgis: string): string => {
	if (rgis.startsWith('"') && rgis.endsWith('"')) {
		return rgis.slice(1, -1);
	}
	return rgis;
};
