'use client';

import Script from 'next/script';
import { getCookie } from '@/utils/cookies';
import { removeEmpty } from '@/utils/remove-empty';
import type { AnalyticsData } from '@/types/entities';
import { useSiteContext } from '@/context/SiteContext';
import { ID_COOKIE } from '@/components/GDPR/constants';
import { useAdService } from '@/context/AdServiceContext';
import { useBreakpoint } from '@/utils/hooks/use-breakpoint';
import { useConsentContext } from '@/context/ConsentContext';
import { serializeExperiments } from '@/utils/serialize-experiments';
import { getIsDemonetized, getDemonetizedReason } from '@/utils/consent/utils';
import {
	events$,
	ARENA_APP_EVENT,
	eventsFilterByType,
	ANALYTICS_GTAG_LOAD,
	ANALYTICS_PAGE_VIEW,
} from '@/utils/events';
import {
	useRef,
	type FC,
	useMemo,
	useState,
	useEffect,
	useContext,
	useCallback,
	createContext,
	type ReactNode,
} from 'react';

type GaCustomDimensions = {
	analytics_tags?: string;
	arena_channel?: string;
	article_id?: string;
	article_title?: string;
	breakpoint?: string;
	character_count?: string;
	correlator?: string;
	debug_event?: string;
	demonetized?: string;
	experiment_variant?: string;
	infinite_scroll_count?: string;
	infinite_scroll_referrer?: string;
	initial_correlator?: string;
	modified_date?: string;
	mutable_publish_date?: string;
	page_type?: string;
	primary_author?: string;
	primary_author_id?: string;
	primary_content_section?: string;
	primary_domain?: string;
	publish_date?: string;
	renderer?: string;
	send_to?: string;
	traffic_type?: string;
	userId?: string;
	video_id?: string;
};
type GaContextType = {
	sendGaEvent: (eventName: string, params: AnalyticsData) => void;
};
type GaContextProviderProps = {
	children: ReactNode;
};
type GaQueueItem = {
	customDimensions: GaCustomDimensions; // Return type of addCustomDimensions();
	data: AnalyticsData;
	eventName: string;
};

// Util for dispatching to Event Bus and Gtag.
const dispatchEvent = (
	eventName: string,
	customDimensions: GaCustomDimensions,
	data: AnalyticsData,
) => {
	if (eventName === 'page_view') {
		events$.next({ type: ANALYTICS_PAGE_VIEW, value: data });
	}
	window.gtag!('event', eventName, customDimensions);
};

export const GaContext = createContext<GaContextType | undefined>(undefined);

export const GaContextProvider: FC<GaContextProviderProps> = ({ children }) => {
	// States
	const { config, gaVars } = useSiteContext();
	const { debugEvent, platformTracker, trafficType } = gaVars;
	const trackerIds = [
		platformTracker,
		...(config.analytics.providers.GoogleAnalytics.trackers?.map(
			(tracker) => tracker.GA4id,
		) || []),
	].filter((id) => !!id);
	const [gaScriptLoaded, setGaScriptLoaded] = useState<Record<string, boolean>>(
		trackerIds.reduce(
			(acc, cur) => {
				acc[cur] = false;
				return acc;
			},
			{} as Record<string, boolean>,
		),
	);
	const [gtagLoaded, setGtagLoaded] = useState(false);
	// Refs
	const gaQueue = useRef<GaQueueItem[]>([]);
	// Hooks
	const { arenaContext } = useConsentContext();
	const isDemonetized = getIsDemonetized(arenaContext);
	const demonetizedReason = getDemonetizedReason(arenaContext);
	const adService = useAdService();
	const breakpoint = useBreakpoint();
	// Memo
	const allScriptsLoaded = useMemo(() => {
		return Object.values(gaScriptLoaded).every((loaded) => loaded);
	}, [gaScriptLoaded]);
	// Utils
	const arenaUidCookie = getCookie(ID_COOKIE);
	const { channelKey, siteProductionDomain } = config;
	// Callbacks
	const clearGaQueue = () => {
		if (gaQueue.current.length > 0) {
			gaQueue.current.forEach(({ customDimensions, data, eventName }) => {
				dispatchEvent(eventName, customDimensions, data);
			});
			gaQueue.current = [];
		}
	};
	const addCustomDimensions = useCallback(
		({
			nonInteraction,
			placement,
			post,
			productItemIds,
			scrollIdx,
			scrollRef,
			sendTo,
			subscriptionType,
			userType,
			videoId,
			widgetId,
		}: AnalyticsData) => {
			const sendIfArticle = (val?: string) => {
				if (/article/.test(post?.meta?.page_type ?? '')) {
					return val ?? '';
				}
				return '';
			};

			return removeEmpty({
				analytics_tags:
					(post?.analytics_tags ?? post?.hidden_terms)
						?.map((term) => term?.name)
						?.join('|') ?? '',
				arena_channel: channelKey,
				article_id: post?.meta?.tempest_id,
				article_title: sendIfArticle(
					post?.meta?.analyticsTitle ?? post?.title?.rendered,
				),
				breakpoint,
				character_count: post?.meta?.characterCount,
				correlator: adService.viewCorrelator,
				debug_event: debugEvent,
				demonetized: isDemonetized ? demonetizedReason : '0',
				experiment_variant: serializeExperiments(adService.experiments),
				infinite_scroll_count: scrollIdx,
				infinite_scroll_referrer: scrollRef,
				initial_correlator: adService.continuousScrollCorrelator,
				modified_date: post?.modified_gmt?.split('T')?.[0],
				mutable_publish_date: post?.date_gmt?.split('T')?.[0],
				non_interaction: nonInteraction,
				page_path: post?.link ? new URL(post.link).pathname : '',
				page_type: post?.meta?.page_type,
				placement,
				primary_author: post?.authors?.[0]?.name,
				primary_author_id: post?.meta?.author_profile_id?.[0]
					? `tm-${post?.meta?.author_profile_id?.[0]}`
					: '',
				primary_content_section: post?.primaryCategory?.name,
				primary_domain: siteProductionDomain,
				product_item_ids: productItemIds,
				publish_date:
					post?.meta?.original_publication_timestamp?.split(' ')?.[0],
				renderer: 'Raven',
				send_to: sendTo,
				subscription_type: subscriptionType,
				traffic_type: trafficType,
				user_type: userType,
				userId: arenaUidCookie,
				video_id: videoId,
				widgetId,
			}) as GaCustomDimensions;
		},
		[
			adService.viewCorrelator,
			adService.continuousScrollCorrelator,
			arenaUidCookie,
			breakpoint,
			demonetizedReason,
			isDemonetized,
			adService.experiments,
			siteProductionDomain,
			channelKey,
			debugEvent,
			trafficType,
		],
	);
	const sendGaEvent = useCallback(
		(eventName: string, data: AnalyticsData) => {
			// customDimensions goes to GA.
			// data is preserved for other trackers e.g. Permutive.
			const customDimensions = addCustomDimensions(data);
			if (allScriptsLoaded && gtagLoaded) {
				clearGaQueue();
				dispatchEvent(eventName, customDimensions, data);
			} else {
				gaQueue.current.push({
					customDimensions,
					data,
					eventName,
				});
			}
		},
		[addCustomDimensions, allScriptsLoaded, gtagLoaded],
	);
	// Clear the queue when gtag global is ready.
	useEffect(() => {
		const subscription = eventsFilterByType(ANALYTICS_GTAG_LOAD).subscribe(
			() => {
				setGtagLoaded(true);
				clearGaQueue();
			},
		);
		return () => {
			subscription.unsubscribe();
		};
	}, []);
	const renderTrackerConfigs = () => {
		return trackerIds
			.map((id) => {
				// Populates the config for the built-in "user_engagement" metrics.
				// Because we can only set it once we populate it with data that
				// won't change during the session. Attaching post data here would
				// render it stale once the page changes.
				const gaConfig = removeEmpty({
					arena_channel: id === platformTracker ? channelKey : '',
					breakpoint,
					demonetized: isDemonetized ? demonetizedReason : '0',
					primary_domain: siteProductionDomain,
					renderer: 'Raven',
					// Non-platform trackers should use the built-in tracking.
					// Platform tracker doesn't use built-in tracking because
					// we need to attach post/page data during SPA page transitions.
					send_page_view: id !== platformTracker,
					userId: arenaUidCookie,
				});
				return `gtag('config', '${id}', ${JSON.stringify(gaConfig)});`;
			})
			.join('');
	};

	return (
		<GaContext.Provider value={useMemo(() => ({ sendGaEvent }), [sendGaEvent])}>
			{children}
			{trackerIds.map((id) => {
				return (
					<Script
						id={`arena-tracker-${id}`}
						key={`arena-tracker-${id}`}
						onLoad={() => {
							setGaScriptLoaded((prevState) => ({
								...prevState,
								[id]: true,
							}));
						}}
						src={`https://www.googletagmanager.com/gtag/js?id=${id}`}
					/>
				);
			})}
			{allScriptsLoaded && (
				<Script
					dangerouslySetInnerHTML={{
						__html: `
							window.dataLayer = window.dataLayer || [];
							function gtag(){window.dataLayer.push(arguments);}
							gtag('js', new Date());
							${renderTrackerConfigs()}
							(function() {
								try {
									window.dispatchEvent(
										new window.CustomEvent(
											"${ARENA_APP_EVENT}",
											{ detail: { type: "${ANALYTICS_GTAG_LOAD}" } }
										)
									);
								} catch(e) {
									console.error(e);
								}
							})();`,
					}}
					id="arena-ga-init"
				/>
			)}
		</GaContext.Provider>
	);
};

export const useGaContext = () => {
	const context = useContext(GaContext);
	if (!context) {
		throw new Error('useGaContext must be used within a GaContextProvider.');
	}
	return context;
};
