'use client';

import type { FirebaseOptions } from 'firebase/app';
import type { ClientSiteConfig } from '@/temporary-site-config';
import type { ToastProps } from '@/components/raven/ui/Toaster/types';
import {
	type FC,
	useMemo,
	useState,
	useContext,
	createContext,
	type PropsWithChildren,
} from 'react';

export type ISiteContext = {
	config: ClientSiteConfig;
	firebase: {
		config: FirebaseOptions;
	};
	gaVars: {
		debugEvent?: string;
		platformTracker: string;
		trafficType?: string;
	};
	host: string;
	newsletter: {
		taigaUrl: string;
	};
	toaster: {
		setToast: React.Dispatch<React.SetStateAction<ToastProps>>;
		toast: ToastProps;
	};
};

// Generic type helper to create context types with guaranteed properties
export type ExtendedContext<T, K extends keyof T> = {
	[P in K]: T[P];
} & Omit<T, K>;

// Specific context types using the generic helper
export type ISiteContextWithToaster = ExtendedContext<ISiteContext, 'toaster'>;

type SiteContextProviderProps = Omit<ISiteContext, 'toaster'> &
	PropsWithChildren;

export const SiteContext = createContext<ISiteContext | null>(null);

export const SiteContextProvider: FC<SiteContextProviderProps> = ({
	children,
	config,
	firebase,
	gaVars,
	host,
	newsletter,
}) => {
	const [toast, setToast] = useState<ToastProps>({} as ToastProps);

	const contextValue = useMemo(
		() => ({
			config,
			firebase,
			gaVars,
			host,
			newsletter,
			toaster: { setToast, toast },
		}),
		[config, firebase, gaVars, host, newsletter, setToast, toast],
	);

	return (
		<SiteContext.Provider value={contextValue}>{children}</SiteContext.Provider>
	);
};

export const useSiteContext = () => {
	const context = useContext(SiteContext);

	if (!context || !context.config) {
		throw new Error('useSiteContext must be used within a SiteContextProvider');
	}

	return context as ISiteContext;
};

// Extended hook for context with guaranteed properties
export const useSiteContextExtended = <K extends keyof ISiteContext>(
	requiredProps: K[],
) => {
	const context = useContext(SiteContext);

	if (!context || !context.config) {
		throw new Error('useSiteContext must be used within a SiteContextProvider');
	}

	// Check if all required properties are present
	const missingProps = requiredProps.filter((prop) => !context[prop]);
	if (missingProps.length > 0) {
		throw new Error(
			`Missing required context properties: ${missingProps.join(', ')}`,
		);
	}

	return context as ExtendedContext<ISiteContext, K>;
};
