import type { IWhiteList } from 'xss';
import type { Identifiers } from '@/types/embeds';
import { ksesAllowedList } from '@headstartwp/core';

// Lazy Load
export const LAZY_WIDTH_THRESHOLD = '100%';
export const LAZY_HEIGHT_THRESHOLD = 'auto';
export const LAZY_ASPECT_RATIO_THRESHOLD = 16 / 9;

// Embeds Scripts Map
export const EmbedScriptsMap: Record<Identifiers, string> = {
	facebook: 'https://connect.facebook.net/en_US/all.js#xfbml=1',
	'google-search':
		'https://cloud.google.com/ai/gen-app-builder/client?hl=en_US',
	instagram: 'https://www.instagram.com/embed.js',
	outbrain: 'https://widgets.outbrain.com/outbrain.js',
	reddit: 'https://embed.reddit.com/widgets.js',
	tiktok: 'https://www.tiktok.com/embed.js',
	twitter: 'https://platform.twitter.com/widgets.js',
};

const instagramAllowList = ['data-instgrm-captioned', 'data-instgrm-permalink'];
const facebookAllowList = ['data-width', 'data-href'];

// ksesAllowedList
export const allowedList: IWhiteList = {
	...ksesAllowedList,
	a: [
		...(ksesAllowedList.a ?? []),
		'data-commerce',
		'data-trackonomics',
		'data-skimlinks',
	],
	blockquote: [
		...(ksesAllowedList.blockquote ?? []),
		'data-video-id',
		'data-embed-from',
		'data-embed-type',
		'data-unique-id',
		...instagramAllowList,
	],
	div: [
		...(ksesAllowedList.div ?? []),
		'data-smart-slot',
		'data-smart-slot-config',
		...facebookAllowList,
	],
	script: [
		'async',
		'crossorigin',
		'defer',
		'integrity',
		'src',
		'data-wp-block-name',
	],
};

// Surfer Constants
export const SURFER_SHOP_URL = 'https://shop.surfer.com/';

export const PREVIEW_COOKIE_NAME = 'ArenaPreview';

// Analytics
export const PLATFORM_TRACKER = process.env.NEXT_PUBLIC_PLATFORM_TRACKER!;
export const ANALYTICS_DEBUG_EVENT =
	process.env.NEXT_PUBLIC_ANALYTICS_DEBUG_EVENT!;
export const ANALYTICS_TRAFFIC_TYPE =
	process.env.NEXT_PUBLIC_ANALYTICS_TRAFFIC_TYPE!;

export const TAIGA_URL = process.env.NEXT_PUBLIC_TAIGA_URL!;
export const POLPO_URL = process.env.POLPO_BASE_URL!;

// Cookies
export const ONE_YEAR_COOKIE = 60 * 60 * 24 * 365;

export const HEADER_HEIGHT_PX = 137;
export const HEADER_HEIGHT_MOBILE = 64;
export const HEADER_HEIGHT_TABLET = 90;

// Defines a limit for the raw length of content we allow for smart slots to process
export const SMART_SLOTS_CONTENT_LENGTH_LIMIT = parseInt(
	process.env.SMART_SLOTS_CONTENT_LENGTH_LIMIT || '0',
	10,
);

// we have one datadome key which we use on both qa and prod
export const DATADOME_CLIENT_SIDE_KEY = '2AC20A4365547ED96AE26618B66966';

export const DOUBLEVERIFY_CTX = '23782275';
export const DOUBLEVERIFY_CMP = 'DV1697745';

// The schema version number of the tempest-schema objects
export const SCHEMA_VERSION = 557;

// OpenWeb API's root URL
export const OW_ROOT_URL = 'https://www.spot.im';
