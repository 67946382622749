import { PrebidBidder } from './base';

/**
 * Ttd (ttd Prebid vendor aka The Trade Desk)
 * Bidder documentation: https://docs.prebid.org/dev-docs/bidders/ttd.html
 * @extends PrebidBidder
 */
export class Ttd extends PrebidBidder {
	static get bidder() {
		return 'ttd';
	}

	bidParams() {
		const sellerId = this.config.publisherId;
		return {
			publisherId: sellerId && sellerId !== '' ? sellerId : '1',
			supplySourceId: this.config.supplySourceId ?? 'thearenagroup',
			// This is supplied via the GPT module
			// placementId: `${slot.adUnitPath}#${zone}-${index}`,
		};
	}
}
