import { PrebidBidder } from './base';

/**
 * Teads (teads Prebid vendor)
 * Bidder documentation: https://docs.prebid.org/dev-docs/bidders/teads.html
 * @extends PrebidBidder
 */
export class Teads extends PrebidBidder {
	static get bidder() {
		return 'teads';
	}

	bidParams(bidRequestConfiguration: BidRequestConfiguration) {
		const { pageId } = this.config;
		const { placementId } = bidRequestConfiguration;
		if (!pageId || !placementId) {
			return null;
		}

		return {
			pageId,
			placementId,
		};
	}
}
