import type { ConsentManagement } from '@/types/gdpr';
import type { SiteConfig } from '@/temporary-site-config';

const DEFAULT_SOURCEPOINT_ACCOUNT_ID = '821';
const DEFAULT_SOURCEPOINT_GDPR_PRIVACY_MANAGER_ID = 755392;
const DEFAULT_SOURCEPOINT_GPP_PRIVACY_MANAGER_ID = 1030954;

// server/web/template-modules/consentManagement/consentManagement.js
export const consentManagement = (
	siteConfig: SiteConfig,
): ConsentManagement => {
	const {
		consentManagement: { sourcepoint },
	} = siteConfig.ad;

	const config = {
		accountId: sourcepoint.accountId || DEFAULT_SOURCEPOINT_ACCOUNT_ID,
		gdprPrivacyManagerId:
			sourcepoint.gdprPrivacyManagerId ||
			DEFAULT_SOURCEPOINT_GDPR_PRIVACY_MANAGER_ID,
		gppPrivacyManagerId:
			sourcepoint.gppPrivacyManagerId ||
			DEFAULT_SOURCEPOINT_GPP_PRIVACY_MANAGER_ID,
		siteId: siteConfig.siteId || null,
	};

	return {
		accountId: parseInt(config.accountId.toString(), 10),
		disableGPP: false,
		gdprPrivacyManagerId: config.gdprPrivacyManagerId,
		gppPrivacyManagerId: config.gppPrivacyManagerId,
	};
};
