import { Zones } from '@/types/ads';
import type { Breakpoint } from 'tempest-common';
import type { Size, Zone, ZoneSizeMap, ZoneHeightMap } from '@/types/ads';

export const ADHESION_SIZE: Size = [1, 1];
export const OUTSTREAM_SIZE: Size = [640, 360];
export const VIDEO_SIZE: Size = [940, 529];
export const FLUID_SIZE: Size = 'fluid';
export const FLUID_1_2_SIZE: Size = [1, 2];
export const BANNER_300_250: Size = [300, 250];
export const BANNER_728_90: Size = [728, 90];
export const BANNER_728_66: Size = [728, 66];
export const BANNER_970_90: Size = [970, 90];
export const BANNER_970_250: Size = [970, 250];
export const BANNER_900_65: Size = [900, 65];
export const BANNER_300_50: Size = [300, 50];
export const BANNER_320_50: Size = [320, 50];
export const BANNER_320_60: Size = [320, 60];
export const BANNER_320_100: Size = [320, 100];
export const BANNER_300_600: Size = [300, 600];
export const BANNER_160_600: Size = [160, 600];

// A list of all in-content banner heights that are used in
// the above constants.
export const IN_CONTENT_BANNER_HEIGHTS = [50, 90, 250];

export const zoneSizeMapping: ZoneSizeMap = {
	[Zones.adhesion]: {
		A: [ADHESION_SIZE],
		B: [ADHESION_SIZE],
		C: [ADHESION_SIZE],
		D: [ADHESION_SIZE],
	},
	[Zones.atf_sidebar]: {
		C: [BANNER_300_250],
		D: [BANNER_300_600, BANNER_300_250, BANNER_160_600],
	},
	[Zones.below_content]: {
		A: [BANNER_300_250, BANNER_320_50, FLUID_SIZE, FLUID_1_2_SIZE],
		B: [
			BANNER_300_250,
			BANNER_728_90,
			BANNER_970_90,
			FLUID_SIZE,
			FLUID_1_2_SIZE,
		],
		C: [BANNER_300_250, BANNER_728_90, FLUID_SIZE, FLUID_1_2_SIZE],
		D: [BANNER_300_250, BANNER_728_90, FLUID_SIZE, FLUID_1_2_SIZE],
	},
	[Zones.fixed_bottom]: {
		A: [BANNER_300_50, BANNER_320_50, BANNER_320_60],
		B: [BANNER_728_90, BANNER_728_66],
		C: [BANNER_728_90, BANNER_900_65, BANNER_970_90],
		D: [BANNER_728_90, BANNER_900_65, BANNER_970_90],
	},
	[Zones.header]: {
		A: [BANNER_320_50, BANNER_320_100],
		B: [BANNER_728_90],
		C: [BANNER_970_90, BANNER_728_90, BANNER_970_250],
		D: [BANNER_970_90, BANNER_728_90, BANNER_970_250],
	},
	[Zones.in_card]: {
		A: [BANNER_300_250, FLUID_SIZE],
		B: [BANNER_300_250, FLUID_SIZE],
		C: [BANNER_300_250, FLUID_SIZE],
		D: [BANNER_300_250, FLUID_SIZE],
	},
	[Zones.in_content]: {
		A: [
			BANNER_300_250,
			BANNER_300_50,
			BANNER_320_50,
			FLUID_SIZE,
			FLUID_1_2_SIZE,
		],
		B: [
			BANNER_300_250,
			BANNER_728_90,
			BANNER_970_90,
			FLUID_SIZE,
			FLUID_1_2_SIZE,
		],
		C: [
			BANNER_300_250,
			BANNER_728_90,
			BANNER_970_90,
			BANNER_970_250,
			FLUID_SIZE,
			FLUID_1_2_SIZE,
		],
		D: [
			BANNER_300_250,
			BANNER_728_90,
			BANNER_970_90,
			BANNER_970_250,
			FLUID_SIZE,
			FLUID_1_2_SIZE,
		],
	},
	[Zones.outstream]: {
		A: [OUTSTREAM_SIZE],
		B: [OUTSTREAM_SIZE],
		C: [OUTSTREAM_SIZE],
		D: [OUTSTREAM_SIZE],
	},
	[Zones.sidebar]: {
		C: [BANNER_300_250],
		D: [BANNER_300_600, BANNER_300_250, BANNER_160_600],
	},
	[Zones.video]: {
		A: [VIDEO_SIZE],
		B: [VIDEO_SIZE],
		C: [VIDEO_SIZE],
		D: [VIDEO_SIZE],
	},
};

export const zoneHeightMapping: ZoneHeightMap = {
	[Zones.below_content]: {
		A: 50,
		B: 90,
		C: 90,
		D: 90,
	},
	[Zones.in_content]: {
		A: 250,
		B: 250,
		C: 90,
		D: 90,
	},
};

export const sizesIncludes = (sizes: Size[], size: Size): boolean =>
	sizes.some((sz) =>
		Array.isArray(sz) && Array.isArray(size)
			? sz[0] === size[0] && sz[1] === size[1]
			: sz === size,
	);

export const sizesSupportedForBreakpoint = (breakpoint: Breakpoint): Size[] => {
	const sizes = new Set<Size>();
	Object.keys(zoneSizeMapping).forEach((zoneId) => {
		const zone = zoneId as Zone;
		if (zoneSizeMapping[zone]?.[breakpoint]) {
			zoneSizeMapping[zone][breakpoint].forEach((size: Size) =>
				sizes.add(size),
			);
		}
	});
	return Array.from(sizes);
};

export const sizesSupportedForZone = (zone: Zone): Size[] => {
	const sizes = new Set<Size>();
	const zoneMap = zoneSizeMapping[zone];
	if (zoneMap) {
		Object.keys(zoneMap).forEach((breakpointId) => {
			const breakpoint = breakpointId as Breakpoint;
			if (zoneMap[breakpoint]) {
				zoneMap[breakpoint].forEach((size: Size) => sizes.add(size));
			}
		});
	}
	return Array.from(sizes);
};

export const isFluidSize = (size: Size): boolean =>
	size === FLUID_SIZE || size === FLUID_1_2_SIZE;

export const isInstreamZone = (zone: Zone): boolean =>
	['instream', 'midroll', 'postroll', 'preroll'].includes(zone);
