import { PrebidBidder } from './base';

/**
 * Pubmatic (pubmatic Prebid vendor)
 * Bidder documentation: https://docs.prebid.org/dev-docs/bidders/pubmatic.html
 * @extends PrebidBidder
 */
export class Pubmatic extends PrebidBidder {
	static get bidder() {
		return 'pubmatic';
	}

	bidParams(bidRequestConfiguration: BidRequestConfiguration) {
		const { publisherId } = this.config;
		if (!publisherId) {
			return null;
		}

		const { siteDomain: pmzoneid, slot: adSlot } = bidRequestConfiguration;
		if (!adSlot) {
			return null;
		}

		return {
			adSlot,
			pmzoneid,
			publisherId,
		};
	}
}
