/* eslint-disable class-methods-use-this */

import Script from 'next/script';
import type { AdSlotModel } from '@/types/ads';
import { serializeExperiments } from '@/utils/serialize-experiments';

import type { AdService } from '../ad-service';
import { AuctionService } from '../auction-service';
import { buildAdUnit, type PrebidAdUnit } from './utils';
import {
	BasePrebidConfig,
	PRICING_MAX_BUCKET,
	AUCTION_VIDEO_TIMEOUT,
	AUCTION_DISPLAY_TIMEOUT,
} from './constants';

type PrebidAuctionResults = {
	auctionId: string;
	bids: Array<unknown>;
	timedOut: boolean;
};

export const PrebidBootstrap = () => (
	<>
		<Script id="pbjs">{`var pbjs=pbjs||{};pbjs.que=pbjs.que||[];`}</Script>
		<Script async src="/js/prebid.min.js" strategy="afterInteractive" />
	</>
);

export class PrebidJS extends AuctionService {
	constructor(adService: AdService) {
		super(adService);

		const config = BasePrebidConfig;
		const { config: siteConfig } = adService.siteContext;
		const { vendors } = siteConfig.ad.bidding;

		config.experimentString = serializeExperiments(adService.experiments);

		// Vendor-specific user sync configuration
		if (vendors.liveramp?.enabled && vendors.liveramp.config?.placementId) {
			config.userSync.userIds.push({
				name: 'identityLink',
				params: {
					pid: vendors.liveramp.config?.placementId,
				},
				storage: {
					expires: 1,
					name: 'idl_env',
					type: 'cookie',
				},
			});
		}

		// UserID configuration for TTD
		if (vendors.ttd?.enabled) {
			config.userSync.userIds.push({
				name: 'unifiedId',
				params: {
					url: `//match.adsrvr.org/track/rid?ttd_pid=${
						vendors.ttd.config?.publisherId || 't0mxacg'
					}&fmt=json`,
				},
				storage: {
					expires: 60,
					name: 'pbjs-unifiedid',
					type: 'cookie',
				},
			});
		}

		// UserID configuration for Verizon ConnectId
		if (vendors.yahooconnect?.enabled) {
			config.userSync.userIds.push({
				name: 'connectId',
				params: {
					// Pixel ID is universal.
					pixelId: 58487,
				},
				storage: {
					expires: 15,
					name: 'connectid',
					type: 'html5',
				},
			});
		}

		if (vendors.liveintent?.enabled) {
			config.userSync.userIds.push({
				name: 'liveIntentId',
				params: {
					distributorId: 'did-0065',
					requestedAttributesOverrides: {
						bidswitch: true,
						index: true,
						magnite: true,
						medianet: true,
						openx: true,
						pubmatic: true,
						sovrn: true,
						thetradedesk: true,
						triplelift: true,
						uid2: true,
					},
				},
				storage: {
					expires: 1,
					name: '__tamLIResolveResult',
					type: 'html5',
				},
			});
		}

		if (vendors.criteo?.enabled) {
			config.userSync.userIds.push({ name: 'criteo' });
		}

		if (siteConfig.analytics.providers.Permutive?.enabled) {
			config.realTimeData.dataProviders.push({
				name: 'permutive',
				params: {
					acBidders: [],
				},
				waitForIt: true,
			});
		}

		if (siteConfig.ad.sellerId) {
			config.schain = {
				config: {
					complete: 1,
					nodes: [
						{
							asi: 'thearenagroup.net',
							hp: 1,
							sid: siteConfig.ad.sellerId,
						},
					],
					ver: '1.0',
				},
				validation: 'strict',
			};
		}

		// TBD: Consent configuration

		// TBD: include ortb2.site.content.keywords for featured video content

		if (!this.pbjs) {
			throw new Error('window.pbjs is not available');
			return;
		}

		this.pbjs.que.push(() => {
			if (!this.pbjs) {
				return;
			}
			// identityLink
			const identityLink = config.userSync?.userIds?.find(
				(id) => id.name === 'identityLink',
			);
			if (identityLink && identityLink.params) {
				this.pbjs.enableAnalytics({
					options: {
						host: 'https://analytics.openlog.in',
						pid: identityLink.params.pid,
					},
					provider: 'atsAnalytics',
				});
			}

			if (typeof window.$p !== 'undefined') {
				this.pbjs.enableAnalytics({
					options: {},
					provider: 'mavenDistributionAnalyticsAdapter', // LiftIgniter
				});
			}

			this.pbjs.setConfig(config);
			this.pbjs.bidderSettings = {
				standard: {
					storageAllowed: true,
				},
			};
		});
	}

	getCpmDollarBucket(cpm: number): number {
		return Math.min(Math.floor(cpm), PRICING_MAX_BUCKET);
	}

	getHighestBid(slotId: string): null | PrebidBid {
		const highest: PrebidBid[] =
			this.pbjs?.getHighestCpmBids(slotId) ?? ([] as PrebidBid[]);
		if (highest && highest.length) {
			return highest[0];
		}
		return null;
	}

	runAuction(slotIds: string[]): Promise<null | PrebidAuctionResults> {
		const slotModels: AdSlotModel[] = this.adService.getSlotModels(slotIds);
		const slots = this.adService.getSlots(slotIds);
		const adUnits: Array<null | PrebidAdUnit> = slotModels
			.map((slot) =>
				buildAdUnit(
					slot,
					this.adService.getSlotRenderCount(slot.slotId),
					this.adService.siteContext.config,
				),
			)
			.filter((adUnit) => adUnit);

		// if no ad units are available, we have no auction to run.
		if (adUnits.length === 0) {
			return Promise.resolve(null);
		}

		// Determine active breakpoint via first slot model
		const { breakpoint } = slotModels[0];

		// Mobile breakpoint is "A"
		const isMobile = breakpoint === 'A';

		// TBD: populate key/value data for: rubicon, pubmatic, appnexus

		// TBD: filter out ad units by geographic restrictions
		// TBD: support for dynamic floors
		return new Promise((resolve) => {
			const bidsBackHandler = (
				bids: Array<PrebidBid>,
				timedOut: boolean,
				auctionId: string,
			) => {
				if (!this.pbjs) {
					// This won't happen, but TypeScript complains about accessing this.pbjs without a check.
					return;
				}

				this.pbjs.setPAAPIConfigForGPT({ auctionId });
				this.pbjs.setTargetingForGPTAsync(slotIds);

				// per-slot targeting adjustments
				slots.forEach((slot) => {
					// Apply a custom 'hb_pbd' (Prebid price bucket dollar) target
					const pb = slot.getTargeting('hb_pb');
					if (pb && pb.length) {
						slot.setTargeting(
							'hb_pbd',
							this.getCpmDollarBucket(parseFloat(pb[0])).toString(),
						);
					}

					// If there is a PMP deal present in the winning bid, we want to
					// set a known, quasi-boolean `deal` target for ease in reporting
					// (since it's easiest to generate reports on known values, and
					// the standard deal ID targeting key, hb_deal, can be any
					// abitrary string).
					//
					// Additionally, if the winning bid is from AppNexus, we'd like to
					// set a targeting value for its member id.
					const deal = slot.getTargeting('hb_deal');
					slot.setTargeting('deal', deal && deal.length ? '1' : '0');
					const adId = slot.getTargeting('hb_adid');
					const highest: null | PrebidBid = this.getHighestBid(
						slot.getSlotElementId(),
					);
					// Belt-and-suspenders check to make sure the winning bid per the
					// highest-CPM function is the same one referenced in the targeting.
					if (highest && adId && adId.length && adId[0] === highest.adId) {
						// If the winning bid is from AppNexus and has a member id,
						// set the targeting. Note that this value is determined by
						// what's in getHighestCpmBids--we *do not* rely on the
						// returned bids array.
						if (highest.appnexus?.buyerMemberId) {
							slot.setTargeting('apnmid', highest.appnexus.buyerMemberId);
						}
					}
				});

				// TBD: support for logging highest bids
				resolve({ auctionId, bids, timedOut });
			};
			const hasVideoBid = adUnits.some((unit) => unit?.mediaTypes?.video);
			this.pbjs?.que.push(() =>
				this.pbjs?.requestBids({
					adUnits,
					bidsBackHandler,
					labels: [isMobile ? 'mobile' : 'desktop', breakpoint],
					ortb2: {
						site: {
							mobile: isMobile ? 1 : 0,
						},
					},
					timeout: hasVideoBid
						? AUCTION_VIDEO_TIMEOUT
						: AUCTION_DISPLAY_TIMEOUT,
				}),
			);
		});
	}

	get pbjs() {
		if (typeof window === 'undefined') {
			return null;
		}
		// Safeguard pbjs.que initialization in case bootstrap script loads after this module
		window.pbjs = window.pbjs || { que: [] };
		return window.pbjs;
	}
}
