export const getElementOffset = (
	element: HTMLElement | null,
): null | number => {
	if (element) {
		const offset =
			element.getBoundingClientRect().top +
			(window.pageYOffset || document.documentElement.scrollTop);
		return offset;
	}

	return null;
};
