export const ANALYTICS_GTAG_LOAD = 'analytics.gtag.load';
export const ANALYTICS_LI_LOAD = 'analytics.liftigniter.load';
export const ANALYTICS_LI_REPORT_ERROR = 'analytics.liftigniter.error.report';
export const ANALYTICS_LI_PUBADS_EVENT = 'analytics.liftigniter.pubads';
export const ANALYTICS_PAGE_VIEW = 'analytics.page.view'; // This excludes "scroll-up" page-views in continuous scroll.
export const ARENA_APP_EVENT = 'arena.app.event';
export const ARENA_PAGE_VIEW = 'arena.page.view'; // This includes "scroll-up" page-views in continuous scroll.
export const ARTICLE_ENTER = 'article.enter';
export const ARTICLE_FETCH = 'article.fetch';
export const ARTICLE_FETCH_COMPLETE = 'article.fetch.complete';
export const NAV_STICKY_ENABLE = 'nav.sticky.enable';
export const USER_ARTICLE_VIEW = 'user.article.view';
export const ARTICLE_TITLE_ENTER = 'article.title.enter';
export const ARTICLE_CONTENT_ENTER = 'article.content.enter';

export type ArenaPageViewEvent = {
	type: typeof ANALYTICS_PAGE_VIEW;
	value: {
		correlator: number;
		path: string;
	};
};
