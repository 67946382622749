import type { FC } from 'react';
import Script from 'next/script';
import type { ClientSiteConfig } from '@/temporary-site-config';
import { DOUBLEVERIFY_CMP, DOUBLEVERIFY_CTX } from '@/constants';

interface DVBootstrapProps {
	config: ClientSiteConfig;
}

export const DoubleVerifyBootstrap: FC<DVBootstrapProps> = ({ config }) => {
	// We'll assume Doubleverify to be enabled by default; if we want to disable it
	// on a per-site basis, we can do so via the disable-doubleverify feature flag.
	if (config.enabledFeatures['disable-doubleverify']) {
		return null;
	}
	return (
		<>
			<Script
				src={`https://pub.doubleverify.com/dvtag/${DOUBLEVERIFY_CTX}/${DOUBLEVERIFY_CMP}/pub.js`}
				strategy="afterInteractive"
			/>
			<Script id="doubleverify-tag">
				{`window.onDvtagReady=function(callback,timeout=750){window.dvtag=window.dvtag||{};dvtag.cmd=dvtag.cmd||[];const opt={callback,timeout,timestamp:new Date().getTime()};dvtag.cmd.push(function(){dvtag.queueAdRequest(opt)});setTimeout(function(){const cb=opt.callback;opt.callback=null;if(cb)cb()},timeout)}`}
			</Script>
		</>
	);
};
