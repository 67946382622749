/* eslint-disable class-methods-use-this */
import { PrebidBidder } from './base';

/**
 * Ix (ix Prebid vendor aka Index Exchange)
 * Bidder documentation: https://docs.prebid.org/dev-docs/bidders/ix.html
 * @extends PrebidBidder
 */
export class Ix extends PrebidBidder {
	static get bidder() {
		return 'ix';
	}

	bidParams(bidRequestConfiguration: BidRequestConfiguration) {
		const { invCode: id, siteId } = bidRequestConfiguration;
		if (!siteId) {
			return null;
		}

		return {
			id,
			siteId,
		};
	}
}
