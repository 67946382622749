import { PrebidBidder } from './base';

/**
 * Rise (rise Prebid vendor)
 * Bidder documentation: https://docs.prebid.org/dev-docs/bidders/rise.html
 * @extends PrebidBidder
 */
export class Rise extends PrebidBidder {
	static get bidder() {
		return 'rise';
	}

	bidParams() {
		const { organizationId: org } = this.config;
		if (!org) {
			return null;
		}

		return {
			org,
		};
	}
}
