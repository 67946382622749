type ScriptOptions = {
	[key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
	async?: boolean;
	defer?: boolean;
	id?: string;
	type?: string;
};

export const loadScript = (
	scriptContent: string,
	options: ScriptOptions = {},
	isScript: boolean = false,
	location: 'footer' | 'header' = 'footer',
	callback?: () => void,
) => {
	// Don't allow duplicate scripts by id.
	if (options?.id) {
		if (document.getElementById(options.id)) {
			return;
		}
	}

	const script = document.createElement('script');

	if (isScript) {
		script.src = scriptContent;
	} else {
		script.textContent = scriptContent;
	}

	// Apply provided options to the script element
	Object.entries(options).forEach(([key, value]) => {
		if (typeof value === 'boolean' && value) {
			script.setAttribute(key, '');
		} else if (value !== undefined) {
			script.setAttribute(key, value.toString());
		}
	});

	if (callback) {
		script.addEventListener('load', callback);
	}

	const parentElement = location === 'header' ? document.head : document.body;

	parentElement.appendChild(script);
};
