import type { ConsentManagement } from '@/types/gdpr';

const ALLOWED_TEST_DOMAINS = [
	'.qa.saymedia.com',
	'.prod.saymedia.com',
	'.thearenagroup.net',
	'localhost',
];

// htdocs/bootscripts/consentManagement/sourcepoint.js
export const initSourcepoint = (consentConfig: ConsentManagement) => {
	window._sp_queue = [];
	window._sp_ = {
		config: {
			accountId: consentConfig.accountId,
			baseEndpoint: 'https://cdn.privacy-mgmt.com',
			events: {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				onMessageChoiceSelect: (message_type, choice_id, choice_type_id) => {
					// TODO: Add Google Analytics event tracking here
				},
			},
		},
	};

	if (
		ALLOWED_TEST_DOMAINS.some((domain) =>
			window.location.hostname.endsWith(domain),
		)
	) {
		window._sp_.config.propertyHref = 'https://www.gpptest.com'; // This domain is added to the allowed list, it should trigger sourcepoint GDPR modal
		window._sp_.config.joinHref = true;
	}
};
