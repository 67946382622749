/* eslint-disable class-methods-use-this */

import type { FC } from 'react';
import Script from 'next/script';
import { Zones } from '@/types/ads';
import type { Size, AdSlotModel } from '@/types/ads';
import type { SiteConfig } from '@/temporary-site-config';

import { AuctionService } from './auction-service';
import { ADHESION_SIZE, isInstreamZone } from './zones';

const APS_AUCTION_TIMEOUT = 1500;

interface AmazonUAMBootstrapProps {
	config: SiteConfig;
}

export const AmazonUAMBootstrap: FC<AmazonUAMBootstrapProps> = ({ config }) => {
	const pubId = config.ad.bidding.vendors?.amazonuam?.config?.pubId;
	const { sellerId } = config.ad;

	return (
		<Script id="amazonuam">
			{
				// apstag bootstrap
				'!function(a9,a,p,s,t,A,g){if(a[a9])return;function q(c,r){a[a9].queue.push([c,r,(new Date).getTime()])}' +
					`a[a9]={init:function(){q('i',arguments)},fetchBids:function(){q('f',arguments)},setDisplayBids:function(){},` +
					`targetingKeys:function(){return[]},deleteId:function(){q('di',arguments)},renewId:function(){q('ri',arguments)},` +
					`updateId:function(){q('ui',arguments)},queue:[]};A=p.createElement(s);A.async=!0;A.src=t;g=p.getElementsByTagName(s)[0];` +
					`g.parentNode.insertBefore(A,g)}('apstag',window,document,'script','https://c.aps.amazon-adsystem.com/apstag.js');` +
					// apstag init
					`(function(){var data={pubID:'${pubId}',adServer:'googletag',videoServer:'DFP'};var sid='${sellerId}';` +
					`if(sid){data.schain={complete:1,ver:'1.0',nodes:[{asi:'thearenagroup.net',sid:sid,hp:1}]}};` +
					`data.signals={ortb2:{site:{page:window.location.href,publisher:{id:'1506fb3f-088b-4f96-973f-5d623b780f83'}}}};` +
					`window.apstag.init(data);window.apstag.queue.push(function(){window.apstag.nativeContent().enable()})})();`
			}
		</Script>
	);
};

function buildAdUnit(slotModel: AdSlotModel): ApsSlot | null {
	const { adUnitPath, index, sizes, slotId, zone } = slotModel;
	const displaySizes = sizes?.filter((size: Size) => Array.isArray(size));

	const adUnitPathParts = adUnitPath.split('/') || [];
	adUnitPathParts.splice(2, 1);
	const slotName = `${adUnitPathParts.join('/')}/${zone}-${index}`;
	const isVideo = isInstreamZone(zone);
	const isAdhesionUnit = sizes?.some((size: Size) => size === ADHESION_SIZE);
	const isFixedBottom = zone === Zones.fixed_bottom;
	if (!isVideo && (!displaySizes || displaySizes.length === 0)) {
		return null;
	}

	return {
		mediaType: isVideo ? 'video' : 'display',
		sizes: isVideo ? [[640, 360]] : displaySizes,
		slotID: isVideo ? 'Default' : slotId,
		slotName,
		...(isAdhesionUnit || isFixedBottom
			? {
					slotParams: {
						ggProduct: 'inScreen',
					},
				}
			: {}),
	};
}

export class AmazonUAM extends AuctionService {
	runAuction(slotIds: string[]): Promise<ApsAuctionResult | undefined> {
		const slotModels: AdSlotModel[] = this.adService.getSlotModels(slotIds);
		const uamUnits = slotModels.map(buildAdUnit).filter((adUnit) => adUnit);

		return new Promise((resolve) => {
			if (!window.apstag || uamUnits.length === 0) {
				resolve(undefined);
				return;
			}

			// const bidMeasure = performanceMeasure('UAM:fetchBids');
			const params: ApsFetchBidsParams = {};
			if (slotModels.some((slotModel) => !!slotModel.refresh)) {
				params.refresh = '1';
			}
			window.apstag.fetchBids(
				{
					params,
					slots: uamUnits,
					// FIXME: should be configurable
					timeout: APS_AUCTION_TIMEOUT,
				},
				(bids: ApsAuctionResult) => {
					// bidMeasure.end();
					window.apstag.setDisplayBids();
					resolve(bids);
				},
			);
		});
	}
}
