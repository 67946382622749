import { PrebidBidder } from './base';

/**
 * ThirtyThreeAcross (33across Prebid vendor)
 * Bidder documentation: https://docs.prebid.org/dev-docs/bidders/33across.html
 * @extends PrebidBidder
 */
export class ThirtyThreeAcross extends PrebidBidder {
	static get bidder() {
		return '33across';
	}

	bidParams(bidRequestConfiguration: BidRequestConfiguration) {
		if (!this.config.siteId) {
			return null;
		}

		return {
			productId: this.isInstreamZone(bidRequestConfiguration.zone)
				? 'instream'
				: 'siab',
			siteId: this.config.siteId,
		};
	}
}
