'use client';

import { isClient } from '@/utils/is-client';
import { tap, share, buffer, filter, mergeAll } from 'rxjs/operators';
import { empty, concat, Subject, fromEvent, type Observable } from 'rxjs';

import { ARENA_APP_EVENT } from './types';

export * from './types';
export type { Observable } from 'rxjs';
export { tap, map, buffer, filter, bufferTime } from 'rxjs';

export interface Event {
	type: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value?: any;
}

export type EventBus = Subject<Event>;
export const events$: EventBus = new Subject<Event>().pipe(
	filter(({ type }) => type !== ARENA_APP_EVENT), // Prevent infinite loop
	tap(
		({ type, value: detail }) =>
			isClient &&
			window.dispatchEvent(new window.CustomEvent(type, { detail })),
	),
	share(),
) as EventBus;

export const eventsFilterByType = (eventType: string) =>
	events$.pipe(filter((e: Event) => e.type === eventType));

export const eventsStartsWith = (eventPrefix: string) =>
	events$.pipe(filter((e: Event) => e.type.startsWith(eventPrefix)));

// eslint-disable-next-line
isClient &&
	window.addEventListener(
		ARENA_APP_EVENT,
		(
			{ detail }: any, // eslint-disable-line @typescript-eslint/no-explicit-any
		) => events$.next(detail),
	);

// eslint-disable-next-line
isClient && new URLSearchParams(document.location.search).has('eventsDebug') && events$.subscribe(console.log);

export const windowError$ = isClient ? fromEvent(window, 'error') : empty();
export const windowUnhandledRejections$ = isClient
	? fromEvent(window, 'unhandledrejection')
	: empty();

// buffer `src$` until `done$` emits, then emit buffered events and let `src$` emit freely
export const bufferUntil = <DoneEvent = unknown, SrcEvent = unknown>({
	done$,
	src$,
}: {
	done$: Observable<DoneEvent>;
	src$: Observable<SrcEvent>;
}) => concat(src$.pipe(buffer(done$), mergeAll()), src$);
