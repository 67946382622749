/* eslint-disable class-methods-use-this */

import type { Zone } from '@/types/ads';

import { isInstreamZone } from '../../zones';

const exclusionList = [
	'constraints',
	'size',
	'zone',
	'geographicRestrictions',
	'invCode',
	'siteDomain',
];

export abstract class PrebidBidder {
	config: VendorConfiguration;

	/**
	 * Creates a PrebidVendor instance, associating the vendor's configuration with the instance.
	 * @param {VendorConfiguration} config The vendor's configuration.
	 */
	constructor(config: VendorConfiguration) {
		this.config = config;
	}

	/**
	 * Provides the PrebidJS bidder code for the Prebid vendor.
	 * This must be overridden by each subclass; the actual class name ends up
	 * getting minified for production, so the class has to provide the actual bidder
	 * code string.
	 * @returns {string} The PrebidJS bidder code.
	 */
	static get bidder(): string {
		throw new Error('bidder method not overridden');
	}

	/**
	 * Returns PrebidJS bid request parameters for the vendor.
	 * @param {BidRequestConfiguration} bidRequestConfiguration The bid request configuration.
	 * @returns {BidParameters | null} An object of key/value pairs for the `params` property of the bid request.
	 */
	bidParams(
		bidRequestConfiguration: BidRequestConfiguration,
	): BidParameters | null {
		return {
			...this.config,
			// values from bidRequestConfiguration, excluding exclusionList properties
			...Object.keys(bidRequestConfiguration).reduce((acc, key) => {
				if (!exclusionList.includes(key)) {
					acc[key] = bidRequestConfiguration[key];
				}
				return acc;
			}, {} as BidParameters),
		};
	}

	/**
	 * Convenience method for testing if a given zone is for instream video.
	 * @param {Zone} zone The zone to check.
	 * @returns {boolean} True if the zone is for instream video, false otherwise.
	 */
	isInstreamZone(zone: Zone): boolean {
		return isInstreamZone(zone);
	}
}
