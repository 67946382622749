import { Zones } from '@/types/ads';
import type { SiteConfig } from '@/temporary-site-config';
import type { Size, Zone, AdSlotModel } from '@/types/ads';

import * as bidders from './bidders';
import type { PrebidBidder } from './bidders/base';
import {
	isFluidSize,
	sizesIncludes,
	isInstreamZone,
	sizesSupportedForZone,
} from '../zones';
import {
	NativeMediaType,
	BannerMediaTypes,
	InstreamMediaType,
	OutstreamMediaType,
} from './constants';

type PrebidBidRequest = {
	bidder: string;
	geographicRestrictions?: GeographicRestriction | null;
	labelAll?: Array<string>;
	params: Record<string, unknown>;
	size?: Size;
};

type PrebidMediaTypeObject = {
	banner?: Record<string, unknown>;
	native?: Record<string, unknown>;
	video?: Record<string, unknown>;
};

export type PrebidAdUnit = {
	bids: Array<PrebidBidRequest>;
	code: string;
	mediaTypes: PrebidMediaTypeObject;
	model: AdSlotModel;
	ortb2Imp: {
		ext: {
			gpid: string;
		};
	};
};

type ZoneProfileMap = {
	[key in Zone]?: Array<PrebidBidRequest>;
};

/**
 * Builds the `mediaTypes` property for a PrebidJS ad unit configuration object.
 * See: https://docs.prebid.org/dev-docs/adunit-reference.html#adunitmediatypes
 * @param {AdSlotModel} slotModel The ad slot model.
 * @returns {PrebidMediaTypeObject} The media types object for the ad slot. The the slot model's zone cannot
 */
function getMediaTypesForSlot(slotModel: AdSlotModel): PrebidMediaTypeObject {
	const { sizes, zone } = slotModel;
	const mediaTypes: PrebidMediaTypeObject = {};
	switch (zone) {
		// Banner ads
		case Zones.in_content: {
			// TODO: Enable this when video ads are being implemented
			// if (slotModel.index === 0) {
			// 	// First in-content slot is eligible for an outstream video
			// 	mediaTypes.video = OutstreamMediaType;
			// }
			//
			// fall through
		}
		case Zones.below_content:
		case Zones.below_content_card:
		case Zones.in_card: {
			// if slot sizes includes a fluid size, add native media type
			if (sizes.some((s) => isFluidSize(s))) {
				mediaTypes.native = NativeMediaType;
			}
			// fall through
		}
		case Zones.fixed_bottom:
		case Zones.header: {
			mediaTypes.banner = {
				...(BannerMediaTypes[zone] ?? {}),
				name: zone,
				sizes: sizes.filter((s) => !isFluidSize(s)),
			};
			break;
		}

		// Outstream
		case Zones.outstream: {
			mediaTypes.video = OutstreamMediaType;
			break;
		}

		// Instream
		case Zones.preroll:
		case Zones.postroll:
		case Zones.midroll:
		case Zones.instream:
		case Zones.video: {
			mediaTypes.video = InstreamMediaType;
			break;
		}

		default: {
			// assume a banner ad if the zone is unrecognized
			mediaTypes.banner = {};
			break;
		}
	}
	return mediaTypes;
}

/**
 * Translates a size configuration string in the format of `widthxheight` to a
 * slot size configuration (array of two integers). If the size does not match
 * the expected format, it is returned as-is. A `1x1` size is recognized as a
 * 'fluid' size.
 * @param {string} size The size configuration string.
 * @returns {Size} The translated slot size.
 */
function configSizeToSlotSize(size: string): Size {
	if (size.match(/^\d+x\d+$/)) {
		const [width, height] = size.split('x').map((s) => parseInt(s, 10));
		if (width === 1 && height === 1) {
			return 'fluid';
		}
		return [width, height];
	}
	return size as Size;
}

/**
 * Builds a map of zones to profiles for auctioning. The structure returned is
 * keyed by zone. The value for each zone is an array of index-based bid requests.
 * Each element of this array can contain one or more bid requests. The bid requests
 * contain size and label configuration so that only the appropriate bid requests
 * for the current breakpoint and submitted for the auction (Prebid does this for us).
 * @returns {ZoneProfileMap} The zone to profile map object.
 */
const buildAuctionProfiles = (() => {
	let zoneToProfileMap: ZoneProfileMap;
	return (siteConfig: SiteConfig) => {
		// Return cached structure if it exists; this only has to be assembled once
		// from site configuration.
		if (zoneToProfileMap) {
			return zoneToProfileMap;
		}

		const bidVendors: Record<string, BidderConfiguration> =
			siteConfig.ad.bidding.vendors;

		const siteDomain = siteConfig.siteProductionDomain;
		const subSiteKeyword = siteConfig.siteBasePath
			? `.${siteConfig.siteKeyword}`
			: '';
		const invPrefix = siteDomain + subSiteKeyword;

		zoneToProfileMap = {};

		const bidProviders: Record<string, PrebidBidder> = {};
		Object.values(bidders).forEach((Bidder) => {
			const bidderConfig = bidVendors[Bidder.bidder];
			if (bidderConfig?.enabled && bidderConfig.bidRequestConfigs?.length) {
				bidProviders[Bidder.bidder] = new Bidder(bidderConfig.config ?? {});
			}
		});

		Object.entries(bidVendors).forEach(([code, bidderConfig]) => {
			const bidProvider = bidProviders[code];
			if (!bidProvider) {
				return;
			}

			const zones = [
				...(new Set(
					bidderConfig.bidRequestConfigs?.map(
						(config: BidRequestConfiguration) => config.zone,
					),
				).values() ?? []),
			] as Zone[];
			zones.forEach((zone: Zone) => {
				const bidsForZone =
					bidderConfig.bidRequestConfigs?.filter(
						(config: BidRequestConfiguration) => config.zone === zone,
					) ?? [];
				if (bidsForZone.length === 0) {
					return;
				}

				const isInstream = isInstreamZone(zone);
				const zoneSizes = sizesSupportedForZone(zone);

				zoneToProfileMap[zone] = zoneToProfileMap[zone] || [];
				const zoneBids: Array<null | PrebidBidRequest> = zoneToProfileMap[zone];

				bidsForZone.forEach((bidConfig: BidRequestConfiguration) => {
					// confirm size is supported for zone
					const bidSize = configSizeToSlotSize(bidConfig.size);
					if (!sizesIncludes(zoneSizes, bidSize)) {
						// bid size is incompatible with zone, so don't bother including it
						return;
					}

					const invCode =
						`${invPrefix}_${isInstream ? 'instream' : zone}` +
						`_INDEX_` +
						`${bidConfig.constraints === 'mobile' ? 'mw' : 'dt'}`;

					// Supply an additional, dynamic bid configuration value: invCode, which
					// is used by some of our vendors to identify the ad slot in their system.
					const params = bidProvider.bidParams({
						...bidConfig,
						invCode,
						siteDomain,
					});
					if (!params) {
						// the bid configuration is invalid
						return;
					}

					// Populate an empty video object for instream zones
					// if it isn't present; many adapters require this.
					if (isInstream && !params.video) {
						params.video = {};
					}

					const brcToUnitBid = (
						bidRequestConfig: BidRequestConfiguration,
					): PrebidBidRequest => ({
						bidder: code,
						size: bidSize,
						...(bidRequestConfig.geographicRestrictions
							? {
									geographicRestrictions:
										bidRequestConfig.geographicRestrictions,
								}
							: {}),
						...(bidRequestConfig.constraints
							? { labelAll: [bidRequestConfig.constraints] }
							: {}),
						params,
					});

					zoneBids.push(brcToUnitBid(bidConfig));
				});
			});
		});
		return zoneToProfileMap;
	};
})();

function applyIndexToBidParams(
	obj: Record<string, unknown>,
	index: number,
): Record<string, unknown> {
	const result = { ...obj };
	Object.keys(obj).forEach((key) => {
		if (typeof obj[key] === 'string') {
			result[key] = obj[key].replace(/INDEX/, String(index));
		}
	});
	return result;
}

function applyIndexToBids(
	bids: Array<PrebidBidRequest>,
	index: number,
): Array<PrebidBidRequest> {
	const result = bids;
	bids.forEach((bid, bidIndex) => {
		if (bid.params) {
			result[bidIndex] = {
				...bid,
				params: applyIndexToBidParams(bid.params, index),
			};
		}
	});
	return result;
}

// Basic deduplication of bid requests based on JSON stringification. Not
// optimal, but doesn't require any additional libraries.
function dedupeBids(bids: Array<PrebidBidRequest>): Array<PrebidBidRequest> {
	const seen: Record<string, boolean> = {};
	return bids.filter((bid) => {
		const key = JSON.stringify(bid);
		if (seen[key]) {
			return false;
		}
		seen[key] = true;
		return true;
	});
}

/**
 * Returns the bid requests for a given ad slot model. If there are multiple
 * bid requests for a given zone, the index property of the slot model is used
 * to determine which bid request to return. If there is no bid request for the
 * given index, the last bid request in the list is used.
 * See: https://docs.prebid.org/dev-docs/adunit-reference.html#adunitbids
 * @param {AdSlotModel} slotModel The ad slot model.
 * @returns {Array<PrebidBidRequest>} The bid requests for the slot.
 */
function getBidsForSlot(
	slotModel: AdSlotModel,
	siteConfig: SiteConfig,
): Array<PrebidBidRequest> {
	const { breakpoint, index, sizes, zone } = slotModel;
	const profiles = buildAuctionProfiles(siteConfig);
	let profileZone = zone;
	const isMobile = breakpoint === 'A';

	// map some special zones to their proper profile zone
	if (zone === Zones.below_content_card) {
		profileZone = Zones.in_card;
	} else if (zone === Zones.below_content_sidebar) {
		profileZone = Zones.sidebar;
	} else if (zone === Zones.outstream_fallback) {
		profileZone = Zones.in_content;
	}
	const slotSizes = sizes || sizesSupportedForZone(profileZone);
	const bids = applyIndexToBids(profiles[profileZone] || [], index);
	return dedupeBids(
		bids
			.filter(
				(bid) =>
					bid.labelAll?.includes(isMobile ? 'mobile' : 'desktop') ?? true,
			)
			.filter((bid) => !bid.size || sizesIncludes(slotSizes, bid.size))
			.map(({ size, ...rest }) => ({ ...rest })),
	);
}

/**
 * Builds a PrebidJS ad unit configuration object for a given ad slot model.
 * See: https://docs.prebid.org/dev-docs/adunit-reference.html
 * @param {AdSlotModel} slotModel The ad slot model.
 * @returns {AdUnit | null} The ad unit configuration object, or null if no bids are available for the slot.
 */
export function buildAdUnit(
	slotModel: AdSlotModel,
	renderCount: number,
	siteConfig: SiteConfig,
): null | PrebidAdUnit {
	const bids = getBidsForSlot(slotModel, siteConfig);
	if (!bids || bids.length === 0) {
		return null;
	}

	const slotModelCopy = { ...slotModel };
	const { adUnitPath, slotId, zone } = slotModelCopy;

	slotModelCopy.index += renderCount;

	// Calculates the GPID string for the ad unit
	const adUnitPathParts = adUnitPath.split('/') || [];
	adUnitPathParts.splice(2, 1);
	const gpid = adUnitPathParts.join('/');

	return {
		bids,
		code: slotId,
		mediaTypes: getMediaTypesForSlot(slotModelCopy),
		model: slotModelCopy,
		ortb2Imp: {
			ext: {
				gpid: `${gpid}/${zone}-${slotModelCopy.index}`,
			},
		},
	};
}
