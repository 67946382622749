import { PrebidBidder } from './base';

/**
 * PulsePoint (pulsepoint Prebid vendor)
 * Bidder documentation: https://docs.prebid.org/dev-docs/bidders/pulsepoint.html
 * @extends PrebidBidder
 */
export class PulsePoint extends PrebidBidder {
	static get bidder() {
		return 'pulsepoint';
	}

	bidParams(bidRequestConfiguration: BidRequestConfiguration) {
		const cp = this.config.cp as string;
		const ct = bidRequestConfiguration.ct as string;
		if (!cp || !ct) {
			return null;
		}

		return {
			cp: parseInt(cp, 10),
			ct: parseInt(ct, 10),
		};
	}
}
