import type { Zone } from '@/types/ads';

import { PrebidBidder } from './base';

/**
 * Rubicon (rubicon Prebid vendor)
 * Bidder documentation: https://docs.prebid.org/dev-docs/bidders/rubicon.html
 * @extends PrebidBidder
 */
export class Rubicon extends PrebidBidder {
	static get bidder() {
		return 'rubicon';
	}

	bidParams(bidRequestConfiguration: BidRequestConfiguration) {
		const accountId = this.config.accountId as string;
		const siteIds = this.config.siteIds as
			| null
			| SiteIdConstraint[]
			| undefined;
		const deviceSiteIds = (siteIds || []).filter(
			(idObj) => idObj.constraints === bidRequestConfiguration.constraints,
		);
		if (!accountId || deviceSiteIds.length === 0) {
			return null;
		}
		const zone = bidRequestConfiguration.zone as Zone;
		const zoneId = bidRequestConfiguration.zoneId as string;
		if (!zoneId) {
			return null;
		}
		const { siteId } = deviceSiteIds[0];
		if (!siteId) {
			return null;
		}

		const params: BidParameters = {
			accountId: parseInt(accountId, 10),
			siteId: parseInt(siteId, 10),
			zoneId: parseInt(zoneId, 10),
		};
		if (this.isInstreamZone(zone)) {
			// Matching the behavior requested in https://github.com/themaven-net/Prebid.js/blob/stable/modules/rubiconBidAdapter.md
			// language is the only required value.
			params.video = {
				language: 'en',
			};
		}
		return params;
	}
}
