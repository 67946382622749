import Script from 'next/script';

export const GptBootstrap = () => (
	<>
		<Script id="googletag">
			{`var googletag=googletag||{};googletag.cmd=googletag.cmd||[];`}
		</Script>
		<Script
			async
			src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
			strategy="afterInteractive"
		/>
	</>
);

export function sanitizeTargetingValue(value: string | string[] = '') {
	function sanitize(v: string) {
		return (
			String(v)
				.trim()
				// https://support.google.com/admanager/answer/10020177
				.replace(/["\\'=!#*~;^()<>[\],&]/g, '')
				.replace(/\s+/g, ' ')
				.replace(/\+/g, 'and')
				.substring(0, 40)
		);
	}

	if (Array.isArray(value)) {
		return value.map((v) => sanitize(v));
	}
	return sanitize(value);
}
