import { useState } from 'react';
import { isClient } from '@/utils/is-client';
import type { Breakpoint } from 'tempest-common';
import { useOnWindowResize } from '@/utils/hooks/use-on-window-resize';

const PixelBoundaries = {
	A: 768,
	B: 1024,
	C: 1440,
};

export function useBreakpoint() {
	const [breakpoint, setBreakpoint] = useState<Breakpoint | null>(null);

	const assignBreakpoint = () => {
		const { innerWidth } = window;
		if (innerWidth < PixelBoundaries.A) {
			setBreakpoint('A');
		} else if (innerWidth < PixelBoundaries.B) {
			setBreakpoint('B');
		} else if (innerWidth < PixelBoundaries.C) {
			setBreakpoint('C');
		} else {
			setBreakpoint('D');
		}
	};

	// update value on resize
	useOnWindowResize(assignBreakpoint);

	if (isClient && breakpoint === null) {
		// assign initial value
		assignBreakpoint();
	}

	return breakpoint;
}
