import type { Breakpoint } from 'tempest-common';

export enum Zones {
	adhesion = 'adhesion',
	atf_sidebar = 'atf_sidebar',
	below_content_card = 'below_content_card',
	below_content_sidebar = 'below_content_sidebar',
	below_content = 'below_content',
	exit_interstitial = 'exit_interstitial',
	fixed_bottom = 'fixed_bottom',
	header = 'header',
	in_card = 'in_card',
	in_content = 'in_content',
	instream = 'instream',
	interstitial = 'interstitial',
	midroll = 'midroll',
	outstream_fallback = 'outstream_fallback',
	outstream = 'outstream',
	postroll = 'postroll',
	preroll = 'preroll',
	sidebar = 'sidebar',
	video = 'video',
}

export type Zone = keyof typeof Zones;

export type Size = 'fluid' | [number, number];

export type SizeMap = {
	[key in Breakpoint]?: Size[];
};

export type HeightMap = {
	[key in Breakpoint]?: number;
};

export type ZoneSizeMap = {
	[key in Zone]?: SizeMap;
};

export type ZoneHeightMap = {
	[key in Zone]?: HeightMap;
};

export type AdSlotModel = {
	adUnitPath: string;
	breakpoint: Breakpoint;
	index: number;
	refresh: number | undefined;
	sizes: Size[];
	slotId: string;
	zone: Zone;
};
