'use client';

import { isClient } from '@/utils/is-client';
import { loadScript } from '@/utils/load-script';
import { isProduction } from '@/utils/is-production';
import { useSiteContext } from '@/context/SiteContext';
import { isMspsState } from '@/utils/consent/is-msps-state';
import { initSourcepoint } from '@/utils/consent/sourcepoint';
import type { SourcepointContext } from '@/types/sourcepoint';
import { consentManagement } from '@/utils/consent/consent-management';
import type {
	ConsentsProps,
	VendorConsents,
	ConsentManagement,
	ArenaCookieContext,
} from '@/types/gdpr';
import {
	getCookie,
	setCookie,
	decodeCookie,
	getDevCookieValue,
	persistentIdCookie,
} from '@/utils/cookies';
import {
	GEO_COOKIE,
	US_DEBUG_CONTEXT,
	ARENA_ENDPOINT_V3,
	EAA_DEBUG_CONTEXT,
} from '@/components/GDPR/constants';
import {
	USP_SCRIPT,
	GPP_SCRIPT,
	TCF_SCRIPT,
	ComplianceType,
	SOURCEPOINT_CLIENT_SCRIPT,
} from '@/utils/consent/constants';
import {
	useRef,
	type FC,
	useState,
	useEffect,
	useContext,
	useCallback,
	createContext,
	type PropsWithChildren,
} from 'react';
import {
	gppAccepted,
	disableGdpr,
	getRegionCode,
	getCountryCode,
	getIsApplicable,
	getIsDemonetized,
	sourcepointToIabMap,
	assumeGdprApplicable,
	consentGivenForPurposes,
	consentGivenForVendorPurposes,
} from '@/utils/consent/utils';

interface ConsentContextValues {
	arenaContext: ArenaCookieContext | null;
	consentConfig?: ConsentManagement;
	consents: null | VendorConsents;
	isGDPRApplicable: boolean;
	sourcepointContext?: null | SourcepointContext;
}

export const ConsentContext = createContext<ConsentContextValues | undefined>(
	undefined,
);

export const ConsentProvider: FC<PropsWithChildren> = ({ children }) => {
	const hasInitialized = useRef(false);
	const { config: siteConfig } = useSiteContext();
	const [consentConfig] = useState(() => consentManagement(siteConfig));
	const [consents, setConsents] = useState<null | VendorConsents>(null);
	const [arenaContext, setArenaContext] = useState<ArenaCookieContext | null>(
		null,
	);
	const [sourcepointContext, setSourcepointContext] =
		useState<null | SourcepointContext>(null);
	const isGDPRApplicable = getIsApplicable(arenaContext);

	const getConsentValues = useCallback(() => {
		window.__tcfapi('addEventListener', 2, (tcdata, success) => {
			if (success && tcdata.vendor && tcdata.purpose) {
				// Get vendor consent
				// https://sourcepoint-public-api.readme.io/reference/getcustomvendorconsents
				window.__tcfapi('getCustomVendorConsents', 2, (data, customSuccess) => {
					// The vendorConsents is  an object of vendorId: boolean e.i { '5e7f6927b8e05c4e491e7380': true }
					const newConsents = {
						purposeConsents: tcdata.purpose.consents,
						vendorConsents: tcdata.vendor.consents,
						vendorPurposeConsents: {} as Record<
							string,
							Record<number | string, boolean>
						>,
					};

					if (customSuccess && data) {
						const { consentedVendors } = data;

						if (consentedVendors && consentedVendors.length) {
							consentedVendors.forEach((vendor) => {
								const { purposeGrants } = data.grants[vendor._id]; // eslint-disable-line no-underscore-dangle

								if (purposeGrants) {
									// This updates the vendorConsents object with the vendorId and the boolean value of the purposeGrants for that vendor
									// If only one is true, the vendor will be added to the list of vendors that have consent
									// eslint-disable-next-line no-underscore-dangle
									newConsents.vendorConsents[vendor._id] = Object.keys(
										purposeGrants,
									).some((key) => purposeGrants[key]);

									// eslint-disable-next-line no-underscore-dangle
									newConsents.vendorPurposeConsents[vendor._id] =
										sourcepointToIabMap(purposeGrants);
								}
							});
						}
					}
					setConsents(newConsents);
				});
			}
		});
	}, []);

	const fetchArenaGeoCookieContext = useCallback(
		async (cookieId: string, didConsent?: boolean) => {
			try {
				let queryString = '';

				if (cookieId) {
					const params = new URLSearchParams({
						uid: cookieId,
					});

					if (didConsent) {
						params.append('didConsent', didConsent.toString());
					}

					queryString = `?${params}`;
				}

				// If the queryString is empty, the service will return a new ArenaContext object.
				const response = await fetch(`${ARENA_ENDPOINT_V3}${queryString}`, {
					credentials: 'include',
				});

				if (!response.ok) {
					throw new Error(
						`[${response.status}] Invalid UID service response ${response.statusText ?? ''}`,
					);
				}

				const data = (await response.json()) as ArenaCookieContext;

				setArenaContext({
					...data,
					isDemonetized: false,
					uid: cookieId || data.uid,
				});
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		},
		[],
	);

	const resolveGeoCookieContext = useCallback(
		(requestedUidValue?: string, didConsent?: boolean) => {
			const cookieId = persistentIdCookie(requestedUidValue);

			try {
				let geoCookie = getCookie(GEO_COOKIE); // The ArenaGeo cookie value is always encoded.

				// This is used to set a local ArenaGeo cookie for development purposes.
				if (!geoCookie && !isProduction()) {
					geoCookie = getDevCookieValue();
					setCookie(GEO_COOKIE, geoCookie);
				}

				// If no geoCookie is found, it will throw an error and fetch the ArenaContext.
				if (!geoCookie) {
					throw new Error('No geoCookie found');
				}

				const decodedCookie = decodeCookie(geoCookie);
				const { countryCode, inEEA, regionCode } = decodedCookie;

				setArenaContext({
					countryCode,
					inEEA,
					isDemonetized: false,
					regionCode,
					uid: cookieId,
				});
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Invalid Cookie Context', error);

				// Fallback to fetchArenaGeoCookieContext if no geoCookie is found.
				fetchArenaGeoCookieContext(cookieId, didConsent);
			}
		},
		[fetchArenaGeoCookieContext],
	);

	useEffect(() => {
		if (disableGdpr()) {
			setArenaContext(US_DEBUG_CONTEXT);
		}

		if (assumeGdprApplicable()) {
			setArenaContext(EAA_DEBUG_CONTEXT);
		}

		if (!disableGdpr() && !assumeGdprApplicable()) {
			resolveGeoCookieContext();
		}
	}, [resolveGeoCookieContext, consentConfig]);

	useEffect(() => {
		if (!arenaContext || hasInitialized.current) {
			return;
		}

		if (
			isGDPRApplicable ||
			getCountryCode(arenaContext) ||
			getRegionCode(arenaContext)
		) {
			hasInitialized.current = true;
			initSourcepoint(consentConfig);

			if (
				!consentConfig.disableGPP &&
				isMspsState(arenaContext.countryCode, arenaContext.regionCode)
			) {
				if (window._sp_.config) {
					window._sp_.config.usnat = {
						includeUspApi: true,
					};
				}

				loadScript(USP_SCRIPT, { id: 'usp-script' });
				loadScript(GPP_SCRIPT, { id: 'gpp-script' });
				loadScript(
					SOURCEPOINT_CLIENT_SCRIPT,
					{ defer: true, id: 'sp-script' },
					true,
					'header',
					() => {
						window.__gpp('addEventListener', (event) => {
							if (
								event.eventName !== 'signalStatus' ||
								event.data !== 'ready'
							) {
								return;
							}

							const gppString = event.pingData?.gppString;
							if (
								gppString &&
								gppString !== sourcepointContext?.consentString
							) {
								setSourcepointContext((prevContext) => ({
									...prevContext,
									consentString: gppString,
									consentType: ComplianceType.GPP,
									gppPrivacyManagerId: consentConfig.gppPrivacyManagerId,
								}));
							}
						});
					},
				);
			} else if (isGDPRApplicable) {
				if (window._sp_.config) {
					window._sp_.config.gdpr = {
						waitForConsent: true,
					};
				}

				loadScript(TCF_SCRIPT, { id: 'tcf-script' });

				// Wait for consent string to load, and gather/initialize our view of it once it
				// is available. Also, refresh internal consent state whenever use modifies them.
				// https://sourcepoint-public-api.readme.io/reference/addeventlistener
				window.__tcfapi('addEventListener', 2, (tcdata, success) => {
					if (success) {
						if (tcdata.eventStatus === 'tcloaded') {
							if (!sourcepointContext?.consentString) {
								setSourcepointContext((prevContext) => ({
									...prevContext,
									consentString: tcdata.tcString,
									consentType: ComplianceType.GDPR,
									gdprPrivacyManagerId: consentConfig.gdprPrivacyManagerId,
								}));
							}

							if (!consents) {
								getConsentValues();
							}
						}

						// This will refresh the page once the user has made a choice
						if (tcdata.eventStatus === 'useractioncomplete') {
							if (tcdata.tcString !== sourcepointContext?.consentString) {
								window.location.reload();
							}
						}
					}
				});

				loadScript(
					SOURCEPOINT_CLIENT_SCRIPT,
					{ defer: true, id: 'sp-script' },
					true,
					'header',
					() => {
						// TODO: Add Google Analytics events
					},
				);
			} else {
				setSourcepointContext(null);
			}
		}
	}, [
		getConsentValues,
		consents,
		isGDPRApplicable,
		arenaContext,
		consentConfig,
		sourcepointContext?.consentString,
	]);

	// eslint-disable-next-line react/jsx-no-constructed-context-values
	const contextValues: ConsentContextValues = {
		arenaContext,
		consentConfig,
		consents,
		isGDPRApplicable,
		sourcepointContext,
	};

	return (
		<ConsentContext.Provider value={contextValues}>
			{children}
		</ConsentContext.Provider>
	);
};

export const useConsentContext = () => {
	const context = useContext(ConsentContext);

	if (!context) {
		throw new Error('useConsentContext must be used within a ConsentProvider');
	}

	return context;
};

export const useCanRender = ({
	gppReject,
	isDemonetized,
	purposes,
	vendorId,
}: ConsentsProps) => {
	const {
		arenaContext,
		consentConfig,
		consents,
		isGDPRApplicable,
		sourcepointContext,
	} = useConsentContext();
	const purposesArray = purposes ?? [];
	const blockGDPRConsent = vendorId || purposesArray.length > 0;

	if (blockGDPRConsent) {
		if (!arenaContext) {
			// before arenaContext is loaded, we can't trust isGDPRApplicable
			return false;
		}
		if (isGDPRApplicable) {
			// This disables GDPR on development mode even if the user is in the EEA.
			if (isClient && disableGdpr()) {
				return true;
			}

			if (!consentConfig) {
				return false;
			}

			if (vendorId) {
				return consentGivenForVendorPurposes(vendorId, purposesArray, consents);
			}

			return consentGivenForPurposes(purposesArray, consents);
		}

		return true;
	}

	if (isDemonetized && arenaContext) {
		return getIsDemonetized(arenaContext);
	}

	if (gppReject) {
		return gppAccepted(sourcepointContext);
	}

	return true;
};
