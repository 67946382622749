'use client';

import { AdService } from '@/utils/ads/ad-service';
import type { Experiment } from '@/types/experiment';
import { useSiteContext } from '@/context/SiteContext';
import {
	type FC,
	useMemo,
	useContext,
	createContext,
	type PropsWithChildren,
} from 'react';

type AdServiceContextType = AdService;

const AdServiceContext = createContext<AdServiceContextType | undefined>(
	undefined,
);
export const useAdService = () => {
	const context = useContext(AdServiceContext);

	if (!context) {
		throw new Error('useAdService must be used within a AdServiceProvider');
	}

	return context;
};

export const AdServiceProvider: FC<
	PropsWithChildren<{
		experiments: Experiment[];
	}>
> = ({ children, experiments }) => {
	const siteContext = useSiteContext();
	const contextValue: AdServiceContextType = useMemo(
		() => new AdService(siteContext, { experiments }),
		[siteContext, experiments],
	);

	return (
		<AdServiceContext.Provider value={contextValue}>
			{children}
		</AdServiceContext.Provider>
	);
};
