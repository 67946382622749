/* eslint-disable class-methods-use-this */
import { PrebidBidder } from './base';

/**
 * Kargo (kargo Prebid vendor)
 * Bidder documentation: https://docs.prebid.org/dev-docs/bidders/kargo.html
 * @extends PrebidBidder
 */
export class Kargo extends PrebidBidder {
	static get bidder() {
		return 'kargo';
	}

	bidParams(bidRequestConfiguration: BidRequestConfiguration) {
		const { placementId } = bidRequestConfiguration;
		if (!placementId) {
			return null;
		}
		return bidRequestConfiguration;
	}
}
