import { safeJSONParse } from '@/utils/json-parse';

const dayPageViewStorage = 'pageViews';

function clearTime(d: Date) {
	d.setHours(0);
	d.setMinutes(0);
	d.setSeconds(0);
	d.setMilliseconds(0);
	return d;
}

export const getPageOfDay = (increment = false) => {
	const ls = window?.localStorage;

	// no support for localStorage, so just return 0
	if (!ls) {
		return 0;
	}
	let pod = safeJSONParse(ls.getItem(dayPageViewStorage) ?? '');
	if (pod?.created) {
		// Shave off hours/minutes/seconds/millseconds for comparing by date,
		// not time.
		const created = clearTime(new Date(pod.created));
		const today = clearTime(new Date());

		// if today's date is not the same as the date of the creation timestamp,
		// clear localstorage and start counting from 0.
		if (created.getTime() !== today.getTime()) {
			ls.removeItem(dayPageViewStorage);
			pod = undefined;
		} else if (increment) {
			pod.value += 1;
		}
	} else {
		pod = undefined;
	}

	if (!pod) {
		pod = { created: new Date(), value: 0 };
	}

	if (increment) {
		ls.setItem(dayPageViewStorage, JSON.stringify(pod));
	}

	return pod.value;
};
