import { Zones } from '@/types/ads';

import { PrebidBidder } from './base';

/**
 * AppNexus (appnexus Prebid vendor)
 * Bidder documentation: https://docs.prebid.org/dev-docs/bidders/appnexus.html
 * @extends PrebidBidder
 */
export class AppNexus extends PrebidBidder {
	static get bidder() {
		return 'appnexus';
	}

	bidParams(bidRequestConfiguration: BidRequestConfiguration) {
		// memberId is required
		const { memberId: member } = this.config;
		if (!member) {
			return null;
		}

		const { invCode, zone } = bidRequestConfiguration;

		// Top-level values
		const params: BidParameters = {
			allowSmallerSizes: true,
			invCode,
			member,
		};

		// When an instream bid request is made, add video parameters
		// according to https://github.com/themaven-net/Prebid.js/blob/stable/modules/appnexusBidAdapter.md
		if (this.isInstreamZone(zone) || zone === Zones.in_content) {
			// TODO: If this is the second video in a pod it should be skippable.
			params.video = {
				playback_method: 'auto_play_sound_off',
				skippable: false,
			};
		}

		// Add in keywords... TBD: client-side?
		// params.keywords = _genericParams(siteInfo, slot, item);
		return params;
	}
}
