import { useRef } from 'react';
import { isClient } from '@/utils/is-client';
import { useEvent } from '@/utils/hooks/use-event';
import { useDebouncedCallback } from '@/utils/hooks/use-debounced-callback';

function getSize(): { height?: number; width?: number } {
	return {
		height: isClient
			? document?.scrollingElement?.scrollHeight || window.innerHeight
			: undefined,
		width: isClient ? window.innerWidth : undefined,
	};
}

const ref = isClient ? { current: window } : { current: null };

/**
 * A custom hooks that runs a handler when the window resizes. This happens
 * out of a render cycle.
 *
 * @param {Function} handler  - Handler to be run
 * @param {number}   debounce - How much to debounce the call, 300ms by default.
 */
export function useOnWindowResize<Fn extends (...args: unknown[]) => unknown>(
	handler: Fn,
	debounce = 300,
) {
	const windowSize = useRef<{ height?: number; width?: number }>(getSize());

	const callback = useDebouncedCallback(
		() => {
			const newSize = getSize();

			if (
				newSize.width !== windowSize.current.width ||
				newSize.height !== windowSize.current.height
			) {
				windowSize.current = newSize;
				handler();
			}
		},
		[],
		debounce,
	);

	useEvent(ref, 'resize', callback);
}
