import { isClient } from '@/utils/is-client';
import { ONE_YEAR_COOKIE } from '@/constants';
import { safeJSONParse } from '@/utils/json-parse';
import type { ArenaCookieContext } from '@/types/gdpr';
import { ID_COOKIE } from '@/components/GDPR/constants';

interface SetCookieOptions {
	domain?: string;
	encode?: boolean;
	expires?: string;
	maxAge?: number;
	path?: string;
	sameSite?: 'Lax' | 'None' | 'Strict';
	secure?: boolean;
}

/**
 * The `getUserId` function returns the userId from a rgisCookie
 * @param {string} rgisCookie - The rgis cookie with format: /^"?\d+|.+$/
 * @returns {string} The used id part of the cookie or empty string when no match
 */
export function getUserId(rgisCookie: null | string = ''): string {
	return rgisCookie?.match(/^"?(\d+)/)?.[1] || '';
}

/**
 * The `getEmailFromRGIS` function returns the email from a rgisCookie
 * @param {string} rgisCookie - The rgis cookie
 * @returns {string} The used id part of the cookie or empty string when no match
 */
export function getEmailFromRGIS(
	rgisCookie: null | string = '',
): null | string {
	if (!rgisCookie) return null;
	const result = /^"?(\d+)\|([^-]+)-0-(\d+)-(.*)"?$/.exec(rgisCookie);
	if (!result) return null;
	const emailAddressBase64 = result[2];
	const email = Buffer.from(emailAddressBase64, 'base64').toString('utf8');
	return email;
}

/**
 * The function `getCookie` retrieves the value of a cookie by its name from the document's cookies.
 * @param {string} name - The `name` parameter in the `getCookie` function is a string that represents
 * the name of the cookie you want to retrieve from the document's cookies.
 * @returns The `getCookie` function returns a string value if a cookie with the specified name is
 * found, or `undefined` if the cookie is not found.
 */
export function getCookie(
	name: string,
	options: { decode?: boolean } = {},
): string | undefined {
	if (!isClient) return undefined;

	const { decode = true } = options;
	const cookies = document.cookie.split('; ');
	const cookie = cookies.find((c) => c.startsWith(`${name}=`));
	if (!cookie) {
		return undefined;
	}
	return decode
		? decodeURIComponent(cookie.split('=')[1])
		: cookie.split(/=(.+)$/)[1];
}

/**
 * The setCookie function in TypeScript sets a cookie with specified name, value, and options like
 * domain, maxAge, path, sameSite, and secure.
 * @param {string} name - The `name` parameter in the `setCookie` function is a string representing the
 * name of the cookie you want to set.
 * @param {string} value - The `value` parameter in the `setCookie` function represents the value you
 * want to store in the cookie for the specified `name`. It is the data that you want to associate with
 * the cookie. For example, if you are setting a cookie to store a user's preferences, the `value
 * @param {SetCookieOptions} options - The `options` parameter in the `setCookie` function is an object
 * that can contain the following properties:
 * - `domain` (optional): A string representing the domain for which the cookie is valid.
 * - `maxAge` (optional): A number representing the maximum age of the cookie in seconds.
 * - `path` (optional): A string representing the path for which the cookie is valid.
 * - `sameSite` (optional): A string representing the SameSite attribute of the cookie. Possible values
 * are 'Lax', 'None', or 'Strict'.
 * - `secure` (optional): A boolean value indicating whether the cookie should only be sent over secure
 * connections.
 */
export function setCookie(
	name: string,
	value: string,
	options: SetCookieOptions = {},
): void {
	const {
		domain,
		encode = true,
		expires,
		maxAge,
		path = '/',
		sameSite = 'Lax',
		secure,
	} = options;
	let cookieString = `${encodeURIComponent(name)}=${encode === true ? encodeURIComponent(value) : value}; path=${path}`;

	if (maxAge !== undefined) {
		cookieString += `; max-age=${maxAge}`;
	}

	if (domain) {
		cookieString += `; domain=${domain}`;
	}

	if (expires) {
		cookieString += `; expires=${expires}`;
	}

	if (secure) {
		cookieString += '; secure';
	}

	if (sameSite) {
		cookieString += `; samesite=${sameSite}`;
	}

	document.cookie = cookieString;
}

/**
 * The `encodeCookie` function encodes an ArenaCookieContext object into a base64 encoded string.
 * @param {ArenaCookieContext} cookie - The `cookie` parameter in the `encodeCookie` function is of
 * type `ArenaCookieContext`, which likely contains information related to a user's session or
 * preferences in an application. This information is being encoded using Base64 encoding after being
 * converted to a JSON string.
 */
export const encodeCookie = (cookie: ArenaCookieContext) =>
	btoa(JSON.stringify(cookie));

/**
 * The `decodeCookie` function decodes a base64-encoded cookie string and parses it as JSON to return
 * an `ArenaCookieContext` object.
 * @param {string} cookie - The `cookie` parameter is a string that represents a cookie value that
 * needs to be decoded.
 */
export const decodeCookie = (cookie: string): ArenaCookieContext =>
	safeJSONParse(atob(cookie));

/**
 * The function `getDevCookieValue` retrieves query parameters from the URL and constructs a cookie
 * object with default values if parameters are not present.
 * @returns The `getDevCookieValue` function returns the encoded cookie value based on the query
 * parameters in the URL. It extracts the values for `countryCode`, `inEEA`, and `regionCode` from the
 * query parameters, with default values set if the parameters are not present. These values are then
 * encoded into a cookie format before being returned.
 */
export const getDevCookieValue = () => {
	const queryParams = new URLSearchParams(window.location.search);

	const cookie = {
		countryCode: queryParams.get('__countryCode') || 'US',
		inEEA: Boolean(queryParams.get('__inEEA')) || false,
		regionCode: queryParams.get('__regionCode') || 'NY',
	};

	return encodeCookie(cookie);
};

/**
 * The function `generateUid` generates a unique identifier using random values from
 * `window.crypto.getRandomValues` and encodes it using base64 with URL-safe characters.
 */
const generateUid = () =>
	btoa(
		String.fromCharCode(
			...Array.from(window.crypto.getRandomValues(new Uint8Array(16))),
		),
	)
		.replace(/\+/g, '-')
		.replace(/\//g, '_')
		.replace(/=/g, '');

/**
 * The `persistentIdCookie` function generates a unique identifier (UID) and stores it in a cookie with
 * a one-year expiration, returning the UID value.
 * @param {string} [requestedUidValue] - The `requestedUidValue` parameter is an optional string value
 * that represents the unique identifier (UID) value that can be provided by the caller of the
 * `persistentIdCookie` function. If this value is provided, it will be used as the UID value for the
 * cookie. If not provided, the
 * @returns The function `persistentIdCookie` returns the `uidValue`, which is either the
 * `requestedUidValue` if provided, or the `existingCookie` if it exists, or a newly generated UID if
 * neither of the previous values are available.
 */
export const persistentIdCookie = (requestedUidValue?: string) => {
	const existingCookie = getCookie(ID_COOKIE);
	const uidValue = requestedUidValue || existingCookie || generateUid();

	setCookie(ID_COOKIE, uidValue, { maxAge: ONE_YEAR_COOKIE });

	return uidValue;
};
