/* eslint-disable class-methods-use-this */
import { PrebidBidder } from './base';

/**
 * Sovrn (sovrn Prebid vendor)
 * Bidder documentation: https://docs.prebid.org/dev-docs/bidders/sovrn.html
 * @extends PrebidBidder
 */
export class Sovrn extends PrebidBidder {
	static get bidder() {
		return 'sovrn';
	}

	bidParams(bidRequestConfiguration: BidRequestConfiguration) {
		const { tagId: tagid } = bidRequestConfiguration;
		if (!tagid) {
			return null;
		}

		return {
			tagid,
		};
	}
}
